import React, { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { wait } from 'src/utils/wait'
import { Box, Stack, Typography, Modal } from '@mui/material'
import { updateField } from 'src/api/fields'
import FieldsForm from 'src/sections/dashboard/field/fields-form'
import { logError } from 'src/utils/logger'

const FieldDetailModal = ({ open, onClose, cardInfo, updateCards }) => {
	const [loading, setLoading] = useState(false)

	const handleSubmit = useCallback(
		async (data, mask, callback) => {
			setLoading(true)
			const filtered = Object.entries(data)
				.filter(([key]) => mask[key])
				.reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
			try {
				const response = await updateField(cardInfo.id, filtered)
				if (response.status === 203) {
					toast.success('Field Updated Successfully!')
					callback()
					updateCards((prev) => {
						const newCard = prev.find((x) => x.id === cardInfo.id)
						for (const key in filtered) {
							if (filtered.hasOwnProperty(key)) {
								newCard[key] = filtered[key]
							}
						}
						return [...prev.filter((x) => x.id !== cardInfo.id), newCard]
					})
					await wait(500)
					onClose()
				} else {
					logError(response, {message:'Field Label already exists?'})
				}
			} catch (e) {
				logError(e)
			} finally {
				setLoading(false)
			}
		},
		[cardInfo.id, updateCards],
	)
	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: { xs: '90%', md: '70%' },
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					borderRadius: 1.5,
				}}
			>
				<Stack alignItems="center" direction="row" spacing={2}>
					<Stack spacing={1}>
						<Typography variant="h4">
							{loading ? 'Loading...' : `Update Details for ${cardInfo?.fieldLabel}`}
						</Typography>
					</Stack>
				</Stack>
				<FieldsForm
					handleSubmit={handleSubmit}
					field={cardInfo}
					loading={false}
					onClose={onClose}
					updateCards={updateCards}
				/>
			</Box>
		</Modal>
	)
}

export default FieldDetailModal
