import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { paths } from 'src/paths'
import { useUserStore } from 'src/store/users'
import { useRouter } from 'src/hooks/use-router'
import { useCallback } from 'react'
import PropTypes from 'prop-types'

export const AccountPopover = (props) => {
	const { anchorEl, onClose, open, ...other } = props
	const user = useUserStore(({ user }) => user)
	const router = useRouter()
	const logoutFunction = useUserStore(({ logout }) => logout)
	const logout = useCallback(() => {
		logoutFunction()
		router.push(paths.login)
	}, [])

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'bottom',
			}}
			disableScrollLock
			onClose={onClose}
			open={!!open}
			PaperProps={{ sx: { width: 200 } }}
			{...other}
		>
			<Box sx={{ p: 2 }}>
				<Typography variant="body1">{user.fullName}</Typography>
				<Typography color="text.secondary" variant="body2">
					{user?.role}
				</Typography>
			</Box>
			<Divider sx={{ my: '0 !important' }} />
			<Box
				sx={{
					display: 'flex',
					p: 1,
					justifyContent: 'center',
				}}
			>
				<Button color="inherit" onClick={() => logout()} size="small">
					Logout
				</Button>
			</Box>
		</Popover>
	)
}

AccountPopover.propTypes = {
	anchorEl: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool,
}
