let availableIdb

// In order to avoid race condition, promisifying the function to chain transactions
const getIdbAsync = () => {
	return new Promise((resolve, reject) => {
		if (availableIdb)
			return resolve(availableIdb)

		// Open the IndexedDB database
		const openedIdb = indexedDB.open('formResults', 1)

		// Create the object store for form results
		openedIdb.onupgradeneeded = function (event) {
			const db = event.target.result
			db.createObjectStore('formResults', { keyPath: 'id', autoIncrement: true })
		}

		openedIdb.onsuccess = () => {
			availableIdb = openedIdb
			resolve(openedIdb)
		}
		openedIdb.onerror = reject
	})
}

// util for saving media in the IndexedDB database
// can be used like await convertToBinary(file)
export const convertToBinary = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.onloadend = () => {
			resolve(reader.result)
		}
		reader.onerror = reject
		reader.readAsArrayBuffer(file)
	})
}

// Add form results to the database
// result: {
//   fields: [{
//     fieldId,
//     value (could be a binary),
//     fileName (if file)
//   }],
//   year,
//   status,
//   token
// }
export const addFormResult = (formResult) => {
	return new Promise(async (resolve, reject) => {
		const idb = await getIdbAsync()
		const transaction = idb.result.transaction(['formResults'], 'readwrite')
		const objectStore = transaction.objectStore('formResults')
		const request = objectStore.add(formResult)
		const registration = await navigator.serviceWorker?.ready
		registration.sync.register('backgroundSync')
		request.onsuccess = resolve
		request.onerror = reject
	})
}

// Get all form results from the database
export const getFormResults = () => {
	return new Promise(async (resolve, reject) => {
		const idb = await getIdbAsync()
		const transaction = idb.result.transaction(['formResults'], 'readonly')
		const objectStore = transaction.objectStore('formResults')
		const request = objectStore.getAll()
		request.onsuccess = () => resolve(request.result)
		request.onerror = reject
	})
}

// Delete a form result from the database
export const deleteFormResult = (id) => {
	return new Promise(async (resolve, reject) => {
		const idb = await getIdbAsync()
		const transaction = idb.result.transaction(['formResults'], 'readwrite')
		const objectStore = transaction.objectStore('formResults')
		const request = objectStore.delete(id)
		request.onsuccess = resolve
		request.onerror = reject
	})
}

export const getFormResultById = (id) => {
	return new Promise(async (resolve, reject) => {
		const idb = await getIdbAsync()
		const transaction = idb.result.transaction(['formResults'], 'readonly')
		const objectStore = transaction.objectStore('formResults')
		const request = objectStore.get(id)
		request.onsuccess = () => resolve(request.result)
		request.onerror = reject
	})
}

export const updateFormResult = (id, updatedFormResult) => {
	return new Promise(async (resolve, reject) => {
		const idb = await getIdbAsync()
		const transaction = idb.result.transaction(['formResults'], 'readwrite')
		const objectStore = transaction.objectStore('formResults')
		const getRequest = objectStore.get(id)
		getRequest.onsuccess = () => {
			const existingFormResult = getRequest.result
			const mergedFormResult = { ...existingFormResult, ...updatedFormResult }
			const updateRequest = objectStore.put(mergedFormResult)
			updateRequest.onsuccess = resolve
			updateRequest.onerror = reject
		}
		getRequest.onerror = reject
	})
}

export const clearAllForms = () => {
	return new Promise(async (resolve, reject) => {
		const idb = await getIdbAsync()
		const transaction = idb.result.transaction(['formResults'], 'readwrite')
		const objectStore = transaction.objectStore('formResults')
		const request = objectStore.clear()
		request.onsuccess = resolve
		request.onerror = reject
	})
}
