import axios from 'src/api/axios'
import { logError } from 'src/utils/logger'

export const getFields = async (page = 1, limit = 10000, label = '') => {
	try {
		const result = await axios.get(`/fields?page=${page}&limit=${limit}&label=${label}`)
		return {
			data: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}
export const getField = async (fieldId) => {
	try {
		const result = await axios.get(`/fields/${fieldId}`)
		return {
			field: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const createField = async (data) => {
	try {
		const response = await axios.post(`/fields`, { ...data, isDeletable: true })
		return {
			data: response.data.data,
			status: response.data.statusCode,
		}
	} catch (err) {
		logError(err)
		return {
			message: err?.response?.data?.message,
			status: err?.response?.data?.statusCode,
		}
	}
}

export const updateField = async (fieldId, data) => {
	try {
		const result = await axios.patch(`/fields/${fieldId}`, data)
		return {
			message: result?.data?.message,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
		return {
			message: err.response.data.message,
			status: err.response.data.statusCode,
		}
	}
}

export const deleteField = async (fieldId) => {
	try {
		const res = await axios.delete(`/fields/${fieldId}`)
		return {
			message: res?.data?.message,
			status: res?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
		return {
			message: err.response.data.message,
			status: err.response.data.statusCode,
		}
	}
}

export const updateMultipleFields = async (data) => {
	try {
		const res = await axios.patch('/fields/multiple', data)
		return {
			message: res?.data?.message,
			status: res?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
		return {
			message: err.response.data.message,
			status: err.response.data.statusCode,
		}
	}
}
