import React, { useMemo } from 'react'
import Slider from 'react-slick'
import { Box, Card, CardContent, Typography } from '@mui/material'

const OfflineCard = () => {
	const tips = useMemo(
		() => [
			{
				title: 'Your Data is Secure',
				content:
					"Even in offline mode, your data is safe and sound. We've saved your updates locally so you can keep working without worries. When you're back online, everything will sync automatically.\n",
			},
			{
				title: 'Work Offline with Confidence',
				content:
					"Don't let connectivity issues slow you down. We've got your back! Your data has been saved offline and will sync up as soon as you're back online. Keep up the good work!",
			},
			{
				title: 'Seamlessly Syncing Soon',
				content:
					"Your edits have been successfully saved offline. Once you're connected to the internet again, our system will work its magic and sync your changes effortlessly. Focus on your tasks – we'll handle the rest!",
			},
		],
		[],
	)
	return (
		<Card
			sx={{ height: '100%', px: 2, mb: 2, border: '1px solid #ccc', boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)' }}
		>
			<CardContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Box sx={{ mb: 6 }}></Box>
				<Box
					sx={{
						flexGrow: 1,
						'& .slick-slider': {
							cursor: 'grab',
						},
						'& .slick-slider, & .slick-list, & .slick-track': {
							height: '100%',
						},
						'& .slick-dots': {
							top: -50,
							bottom: 'unset',
							left: -10,
							textAlign: 'left',
						},
					}}
				>
					<Slider arrows={false} dots infinite speed={500} slidesToShow={1} slidesToScroll={1}>
						{tips.map((tip) => (
							<div key={tip.title}>
								<Typography variant="h6">{tip.title}</Typography>
								<Typography color="text.secondary" sx={{ mt: 1 }} variant="body1">
									{tip.content}
								</Typography>
							</div>
						))}
					</Slider>
				</Box>
			</CardContent>
		</Card>
	)
}

export default OfflineCard
