import { useState, useEffect } from "react"

const useOnline = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  useEffect(() => {
    const handleOnlineEvent = async () => {
      setIsOnline(true)
      toast.success('Back Online!')
    }

    const handleOfflineEvent = () => {
      setIsOnline(false)
    }

    window.addEventListener('online', handleOnlineEvent)
    window.addEventListener('offline', handleOfflineEvent)

    return () => {
      window.removeEventListener('online', handleOnlineEvent)
      window.removeEventListener('offline', handleOfflineEvent)
    }
  }, [])

  return isOnline
}

export default useOnline