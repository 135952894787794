import React, { useState, useMemo, useCallback, memo } from 'react'
import {
	FormControl,
	InputLabel,
	Box,
	Typography,
	Modal,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Chip,
} from '@mui/material'
import { useFormStateStore } from 'src/store/formState'
import { Scrollbar } from 'src/components/scrollbar'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const FieldHistory = memo(({ selectedField, setSelectedField }) => {
	const style = useMemo(
		() => ({
			position: 'absolute',
			top: '50%',
			left: '50%',
			width: '60%',
			transform: 'translate(-50%, -50%)',
			bgcolor: 'background.paper',
			p: 3,
		}),
		[],
	)
	const formStore = useFormStateStore()

	const history = useMemo(
		() =>
			formStore.orphanForms
				?.map((x) => ({
					year: x?.year,
					fields: x?.fields?.filter((field) => field?.fieldId == selectedField.id)?.[0],
				}))
				?.filter((x) => x?.fields),
		[selectedField, formStore.orphanForms],
	)

	return (
		<Modal
			open={!!selectedField.id}
			onClose={() => setSelectedField({ id: '', name: '' })}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography id="modal-modal-title" variant="h6" component="h2" sx={{ py: 2 }}>
					Select an Old Value for {selectedField?.name}
				</Typography>
				<div>
					<Scrollbar>
						<Table sx={{ minWidth: 400, maxHeight: 100 }}>
							<TableHead sx={{ visibility: 'visible' }}>
								<TableRow>
									<TableCell>Value</TableCell>
									<TableCell>Year</TableCell>
									<TableCell align="right">Update</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{history?.map((form, index) => (
									<TableRow
										hover
										key={index}
										onClick={() => {
											formStore.fillForm([
												...formStore.form?.filter((x) => x?.field != form?.fields?.fieldId),
												{ fieldId: form?.fields?.fieldId, value: form?.fields?.value },
											])
											setSelectedField({
												id: '',
												name: '',
											})
										}}
										sx={{
											cursor: 'pointer',
										}}
									>
										<TableCell>{form?.fields?.value}</TableCell>
										<TableCell>{form?.year}</TableCell>
										<TableCell align="right">
											<IconButton
												component="a"
												onClick={() => {
													formStore.fillForm([
														...formStore.form?.filter(
															(x) => x?.field != form?.fields?.fieldId,
														),
														{ fieldId: form?.fields?.fieldId, value: form?.fields?.value },
													])
													setSelectedField({
														id: '',
														name: '',
													})
												}}
											>
												<ChevronRightIcon fontSize="small" />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Scrollbar>
				</div>
			</Box>
		</Modal>
	)
})

export default FieldHistory
