import { getSignedUrl } from 'src/api/forms'
import { toast } from 'react-hot-toast'
import axios from 'axios'
import { logError } from 'src/utils/logger'

export const uploadImageWithRetries = async (file) => {
  const retries = 3;
  for (let attempt = 0; attempt <= retries; attempt++) {
    try {
      const presignedPostData = await getSignedUrl(file.name)
      //console.log(presignedPostData)
      const imageUrl = presignedPostData.data.imageUrl
      const uploadUrl = presignedPostData.data.data.url
      const formData = new FormData()
      Object.entries(presignedPostData.data.data.fields).forEach(([key, value]) => {
        formData.append(key, value)
      })
      formData.append('file', file)

      // finally upload it to aws
      const s3UploadResult = await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': presignedPostData.data.data.fields['Content-Type'],
          'Access-Control-Allow-Origin': '*',
        },
      })
      //console.log(s3UploadResult)
      return imageUrl
    } catch (error) {
      logError(error)
      if (attempt === retries) {
        toast.error('Failed to upload file')
      }
    }
  }
}

// this happens per field, since it can have multiple files, batch them
export const uploadImagesGetStringified = async (files) => {
  //console.log('fet uploadImagesGetStringified')
  try {
    const batchSize = 2
    const results = []
    for (let i = 0; i < files.length; i += batchSize) {
      const batch = files.slice(i, i + batchSize)
      //console.log('batch ' + 1, batch)
      const batchPromises = batch.map(async (x) => {
        const values = await uploadImageWithRetries(x)
        //console.log(values)
        if (typeof values === 'string') {
          return values
        }
      })
      const batchResults = await Promise.all(batchPromises)
      results.push(...batchResults)
    }
    return JSON.stringify(results.filter(Boolean))
  } catch (e) {
    logError(e)
  }
}
