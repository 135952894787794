import { useState, useEffect } from 'react';
import { logError } from 'src/utils/logger'

const indexedDBOpenDB = (dbName, storeName, version = 1) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, version);
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }
    };
  });
};

const indexedDBGetItem = (db, storeName, key) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readonly');
    const store = transaction.objectStore(storeName);
    const request = store.get(key);
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);
  });
};

const indexedDBSetItem = (db, storeName, key, value) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const store = transaction.objectStore(storeName);
    const request = store.put(value, key);
    request.onerror = () => reject(request.error);
    request.onsuccess = () => resolve(request.result);
  });
};

export const useIndexedDB = (storeName, key = 0, defaultValue = {}) => {
  const [state, setState] = useState(defaultValue)

  useEffect(() => {
    let db;
    indexedDBOpenDB('orphans', storeName)
      .then((openedDB) => {
        db = openedDB;
        return indexedDBGetItem(db, storeName, key)
      })
      .then((saved) => {
        if (saved) {
          setState(saved)
        }
      })
      .catch((err) => {
        logError(err, {src:'src/pages/dashboard/field/fields-form.jsx/FieldsForm/handleSubmit'})
      });
    return () => {
      db?.close()
    }
  }, [key, storeName])


  const set = (val) => {
    indexedDBOpenDB('orphans', storeName)
      .then((db) => {
        return indexedDBSetItem(db, storeName, key, val)
      })
      .then(() => {
        setState(val)
      })
      .catch((err) => {
        logError(err, {message:'Could not save the value to IndexedDB'})
      })
  }
  return [state, set]
};
