import axios from 'src/api/axios'
import { logError } from 'src/utils/logger'

export const getOrphans = async (page = 1, limit = 10, searchQuery = '') => {
	try {
		const result = await axios.get(`/orphans?page=${page}&limit=${limit}&searchQuery=${searchQuery}`)
		return {
			data: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const getOrphan = async (id) => {
	try {
		const result = await axios.get(`/orphans/${id}`)
		return {
			orphan: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const assignOrphan = async (orphanId, data) => {
	try {
		const response = await axios.post(`/orphans/${orphanId}`, data)
		return {
			data: response.data.data,
			status: response.data.statusCode,
		}
	} catch (err) {
		return {
			message: err?.response?.data?.message,
			status: err?.response?.data?.statusCode,
		}
	}
}
