import React from 'react'
import { Scrollbar } from 'src/components/scrollbar'
import {
	Skeleton,
	Avatar,
	Chip,
	Box,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material'
import { getInitials } from 'src/utils/getInitials'
import { useRouter } from 'src/hooks/use-router'

const FormsTable = ({ orphan, userRole, forms, formsCount, onPageChange, onRowsPerPageChange, page, rowsPerPage, loading }) => {
	const router = useRouter()
	return (
		<Box sx={{ position: 'relative' }}>
			<Scrollbar>
				<Table sx={{ minWidth: 700 }}>
					<TableHead sx={{ visibility: 'visible' }}>
						<TableRow>
							<TableCell>Form ID</TableCell>
							{(userRole === 'Gatherer' || userRole === 'SuperAdmin') && <TableCell>Orphan</TableCell>}
							<TableCell>Year</TableCell>
							<TableCell>Created By</TableCell>
							<TableCell>Created At</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loading && (
							<>
								{[1, 2, 3, 4, 5, 6]?.map((index) => (
									<TableRow hover key={index}>
										<TableCell>
											<Box
												sx={{
													alignItems: 'center',
													display: 'flex',
												}}
											>
												<Skeleton variant="circular" width={40} height={40} />
												<Box sx={{ ml: 1, width: 200 }}>
													<Skeleton animation="wave" />
												</Box>
											</Box>
										</TableCell>
										{(userRole === 'Gatherer' || userRole === 'SuperAdmin') && (
											<TableCell>
												<Box
													sx={{
														alignItems: 'center',
														display: 'flex',
													}}
												>
													<Skeleton variant="circular" width={40} height={40} />
													<Box sx={{ ml: 1, width: 200 }}>
														<Skeleton animation="wave" />
													</Box>
												</Box>
											</TableCell>
										)}
										<TableCell>
											<Skeleton width={150} />
										</TableCell>
									</TableRow>
								))}
							</>
						)}
						{!loading &&
							forms?.map((form) => (
								<TableRow
									hover
									key={form?.id}
									onClick={() => router.push(`/forms/${form?.id}`)}
									sx={{ cursor: 'pointer' }}
								>
									<TableCell>
										<Chip label={form?.id} />
									</TableCell>
									{(userRole === 'Gatherer' || userRole === 'SuperAdmin') && (
										<TableCell>
											<Box
												sx={{
													alignItems: 'center',
													display: 'flex',
												}}
											>
												<Avatar
													src={form?.orphanId?.photoUrl}
													sx={{
														height: 42,
														width: 42,
													}}
												>
													{getInitials(
														`${orphan?.firstName || form?.orphanId?.firstName} ${orphan?.lastName || form?.orphanId?.lastName}`,
													)}
												</Avatar>
												<Box sx={{ ml: 1 }}>
													{(userRole === 'Gatherer' || userRole === 'SuperAdmin') && (
														<Typography>{orphan?.id || form?.orphanId?.id}</Typography>
													)}
													<Typography color="textSecondary" variant="body2">
														{`${orphan?.firstName || form?.orphanId?.firstName} ${orphan?.lastName || form?.orphanId?.lastName}`}
													</Typography>
												</Box>
											</Box>
										</TableCell>
									)}
									<TableCell>
										{form?.year}
									</TableCell>
									<TableCell>
										<Box
											sx={{
												alignItems: 'center',
												display: 'flex',
											}}
										>
											<Avatar
												src={form?.createdBy?.photoUrl}
												sx={{
													height: 42,
													width: 42,
												}}
											>
												{getInitials(form?.createdBy?.fullName)}
											</Avatar>
											<Box sx={{ ml: 1 }}>
												<Typography>{form?.createdBy?.fullName}</Typography>
												<Typography color="textSecondary" variant="body2">
													{form?.createdBy?.username}
												</Typography>
											</Box>
										</Box>
									</TableCell>
									<TableCell>
										{typeof form?.createdAt !== 'number'
											? new Date(form?.createdAt).toDateString()
											: form?.createdAt}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</Scrollbar>
			{!loading && (
				<TablePagination
					component="div"
					count={formsCount || 0}
					onPageChange={onPageChange}
					onRowsPerPageChange={onRowsPerPageChange}
					page={page}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[5, 10, 25]}
				/>
			)}
		</Box>
	)
}

export default FormsTable
