import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'src/hooks/use-router'
import { useUserStore } from 'src/store/users'
import { paths } from 'src/paths'

export const AuthGuard = (props) => {
	const { children, roles = ['SuperAdmin', 'Gatherer', 'Admin', 'Editor'] } = props
	const router = useRouter()
	const user = useUserStore(({ user }) => user)
	const [checked, setChecked] = useState(false)

	const check = useCallback(() => {
		if (!roles.includes(user.role)) {
			const searchParams = new URLSearchParams({ returnTo: window.location.pathname }).toString()
			router.replace(paths.login + `?${searchParams}`)
		} else {
			setChecked(true)
		}
	}, [roles, user, router])

	// Only check on mount, this allows us to redirect the user manually when auth state changes
	useEffect(
		() => {
			check()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	if (!checked) {
		return null
	}

	// If got here, it means that the redirect did not occur, and that tells us that the user is
	// authenticated / authorized.

	return <>{children}</>
}
