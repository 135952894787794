import React, { useState,useImperativeHandle, useRef, useCallback } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import { TableCell, TableRow, IconButton, Tooltip, Button, Box } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Minus } from '@untitled-ui/icons-react'
import { Edit } from '@mui/icons-material'
import PopupModal from 'src/sections/components/popup'
import FieldDetailModal from 'src/sections/dashboard/field/field-detail-modal'
import usePopupModal from 'src/hooks/use-popup-modal'
import { deleteField } from 'src/api/fields'

const Card = React.forwardRef(({
		cardInfo,
		isDragging,
		connectDragSource,
		connectDropTarget,
		setFields,
		index,
		setOriginalFields,
		onFieldDelete,
		fieldCheck,
		section,
	},
	ref,
	) => {
		const elementRef = useRef(null)
		connectDragSource(elementRef)
		connectDropTarget(elementRef)

		//const opacity = isDragging ? 0 : 1

		const cardStyle = isDragging ? {backgroundColor:'lightskyblue'} : {}

		useImperativeHandle(ref, () => ({
			getNode: () => elementRef.current,
		}))
		const [isModalOpened, setIsModalOpen] = useState(false)

		const handleOpenTheModal = () => setIsModalOpen(true)
		const handleCloseTheModal = () => setIsModalOpen(false)

		const {
			isModalOpen,
			loading: deleteLoading,
			handleDeleteClick,
			handleConfirmDelete,
			handleCloseModal,
		} = usePopupModal(deleteField, 'Field Deleted Successfully!', onFieldDelete)

		const handleRemoveCard = useCallback((id) => {
			setFields((prev) => prev.filter((x) => x.id !== id))
			setOriginalFields((prev) => {
				const fields = prev.fields?.map((x) => {
					if (x?.id === id) {
						return { ...x, checked: false }
					}
					return x
				})
				return {
					fields,
				}
			})
		}, [])

		return (
			<TableRow ref={elementRef} style={cardStyle}>
				<PopupModal
							isModalOpen={isModalOpen}
							handleConfirmDelete={handleConfirmDelete}
							handleCloseModal={handleCloseModal}
						/>
						<TableCell>
							<IconButton>
								<MenuIcon />
							</IconButton>
						</TableCell>
						<TableCell>{cardInfo?.fieldLabel}</TableCell>
						<TableCell align="right">
							{!fieldCheck ? (
								<Tooltip title="Remove From Preset">
									<IconButton component="a" onClick={() => handleRemoveCard(cardInfo?.id)}>
										<Minus fontSize="small" color="#ff5555" />
									</IconButton>
								</Tooltip>
							) : (
								<>
									{cardInfo?.isDeletable ? (
										<Button
											color="error"
											variant="outlined"
											onClick={(e) => {
												e.preventDefault()
												e.stopPropagation()
												handleDeleteClick(cardInfo)
											}}
											sx={{ marginRight: 2 }}
										>
											Delete
										</Button>
									) : (
										<Box sx={{ width: 120 }}></Box>
									)}

									<Tooltip title="Update Field">
										<IconButton component="a" onClick={handleOpenTheModal}>
											<Edit fontSize="small" color="#ff5555" />
										</IconButton>
									</Tooltip>
									<FieldDetailModal
										open={isModalOpened}
										onClose={handleCloseTheModal}
										cardInfo={cardInfo}
										updateCards={setFields}
									/>
								</>
							)}
						</TableCell>
			</TableRow>
		)
	}
)
export default DropTarget(
	'card',
	{
		drop(props) {
			props.saveFunc()
		},
		hover(props, monitor, component) {
			if (!component) return null
			const dragItem = monitor.getItem()
			const dragIndex = dragItem.index
			const hoverIndex = props.index
			// Don't replace items with themselves
			if (dragIndex === hoverIndex) return
			//If not same section stop
			if (dragItem.section !== props.section) return

			const node = component.getNode()
			if (!node) return null

			// Determine rectangle on screen
			const hoverBoundingRect = node.getBoundingClientRect()
			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
			// Determine mouse position
			const clientOffset = monitor.getClientOffset()
			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;
		  
			// Introduce a threshold to limit the number of times moveCard is called
			const threshold = hoverMiddleY / 4;  // 25% of the item height
			
			// Calculate the upper and lower hover boundaries based on the threshold
			const lowerHoverBoundary = hoverMiddleY - threshold;
			const upperHoverBoundary = hoverMiddleY + threshold;
		  
			// Only perform the move operation if we cross the threshold into another card
			const crossedLowerIntoAnother = dragIndex < hoverIndex && hoverClientY > lowerHoverBoundary;
			const crossedUpperIntoAnother = dragIndex > hoverIndex && hoverClientY < upperHoverBoundary;
		  
			if (crossedLowerIntoAnother || crossedUpperIntoAnother) {
			  props.moveCard(dragIndex, hoverIndex);
			  monitor.getItem().index = hoverIndex;
			}
		}
	},
	(connect) => ({
		connectDropTarget: connect.dropTarget(),
	}),
)(
  DragSource(
    'card',
		{
			beginDrag: (props) => ({
				id: props.id,
				section: props.section,
				index: props.index,
			}),
		},
		(connect, monitor) => ({
			connectDragSource: connect.dragSource(),
			isDragging: monitor.isDragging(),
		}),
  )(Card)
)
