
import axios from 'src/api/axios'
import { logError } from 'src/utils/logger'

export const getForms = async (page = 1, limit = 10, searchQuery = '', status = 'draft') => {
	const result = await axios.get(`/forms?page=${page}&limit=${limit}&status=${status}&searchQuery=${searchQuery}`)
	return {
		data: result?.data?.data,
		status: result?.data?.statusCode,
	}
}

//throws error when offline - catch is done in this way intentionally
export const getForm = async (formId) => {
	try {
		const result = await axios.get(`/forms/${formId}`)
		return {
			form: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		//when offline: the err code is ERR_NETWORK
		if (err && err.code != "ERR_NETWORK") {
			const result = {
				message: err.response?.data?.message,
				status: err.response?.data?.statusCode,
			}
			logError(err)
			return result
		}
		else throw Error(err)
	}
}

export const getOrphanForms = async (orphanId, page = 1, limit = 10, status = 'submitted') => {
	try {
		const result = await axios.get(`/forms/orphan/${orphanId}?page=${page}&limit=${limit}&status=${status}`)
		return {
			forms: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const getOrphanFormsHistory = async (orphanId, page = 1, limit = 10, status = 'completed') => {
	try {
		const result = await axios.get(`/forms/history/orphan/${orphanId}?page=${page}&limit=${limit}&status=${status}`)
		return {
			forms: result?.data?.data,
			status: result?.data?.statusCode,
		}
	} catch (err) {
		logError(err)
	}
}

export const createForm = async (data) => {
	const response = await axios.post(`/forms`, data)
	return {
		data: response?.data?.data,
		status: response?.data?.statusCode,
	}
}
export const deleteForm = async (formId) => {
	const result = await axios.delete(`/forms/${formId}`)
	return {
		message: result?.data?.message,
		status: result?.data?.statusCode,
	}
}

export const updateForm = async (formId, data) => {
	const result = await axios.patch(`/forms/${formId}`, data)
	return {
		message: result?.data?.message,
		status: result?.data?.statusCode,
	}
}

export const getSignedUrl = async (fileName) => {
	const response = await axios.post(`/forms/upload-image?fileName=${fileName}`)
	return {
		data: response.data,
		status: response.data.statusCode,
	}
}

export const getImage = async (key) => {
	const response = await axios.get(`/forms/assets/image`, {
		params: { key },
	})
	return response
}
