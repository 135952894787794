import React, { useState, useEffect, useCallback } from 'react'
import { wait } from 'src/utils/wait'
import { useFieldsStore } from 'src/store/fields'
import { useUserStore } from 'src/store/users'
import { useRouter } from 'src/hooks/use-router'
import { Grid, Box, Card, Chip, Container, LinearProgress, useMediaQuery, Link, Stack, SvgIcon, Typography } from '@mui/material'
import DynamicForm from 'src/sections/dashboard/forms/dynamic-form'
import { getForm, updateForm, deleteForm } from 'src/api/forms'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useFormStateStore } from 'src/store/formState'
import { fileToBase64 } from 'src/utils/file-to-base64'
import { RouterLink } from 'src/components/router-link'
import { paths } from 'src/paths'
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft'
import {
	convertToBinary,
	deleteFormResult,
	getFormResultById,
	updateFormResult,
} from 'src/utils/indexedDb'
import moment from 'moment/moment'
import OfflineCard from 'src/sections/dashboard/forms/offline-card'
import { PropertyList } from 'src/components/property-list'
import { PropertyListItem } from 'src/components/property-list-item'
import { logError } from 'src/utils/logger'
import { uploadImagesGetStringified } from 'src/utils/upload-images'

const FormDetailsPage = () => {
	const [loading, setLoading] = useState(false)
	const [formLoading, setFormLoading] = useState(false)
	const [formInfo, setFormInfo] = useState(false)
	const user = useUserStore(({ user }) => user)
	const { formId } = useParams()
	const fields = useFieldsStore(({ fields }) => fields)
	const setFields = useFieldsStore(({ setFields }) => setFields)
	const fillForm = useFormStateStore(({ fillForm }) => fillForm)
	const emptyForm = useFormStateStore(({ emptyForm }) => emptyForm)
	const [formStatus, setFormStatus] = useState('')
	const [isFormCreatedOffline, setIsFormCreatedOffline] = useState(false)
	const router = useRouter()
	const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
	const align = mdUp ? 'horizontal' : 'vertical'

	const getFormDetailsCallback = useCallback(async () => {
		setFormLoading(true)
		try {
			const res = await getForm(formId)
			if (res?.status === 200) {
				setIsFormCreatedOffline(false)
				setFormStatus(res?.form?.status)
				// if (res?.form?.status === 'draft' && user?.role !== 'Gatherer' user?.role !== 'SuperAdmin') {
				// 	router.push('/form-not-found')
				// }
				setFormInfo(res?.form)
				fillForm(res?.form.formfields)
				setFields(res?.form.formfields)
			} else {
				router.push('/form-not-found')
			}
		} catch (e) {
			const res = await getFormResultById(parseInt(formId))
			fillForm(res?.fields)
			setIsFormCreatedOffline(true)
		} finally {
			setFormLoading(false)
		}
	}, [user?.role, formId, setFields])
	const handleDelete = useCallback(async () => {
		setLoading(true)
		try {
			result = await deleteForm(formId)
			if (result.status == 200)
				toast.success('Form Deleted Successfully!')
			else {
				logError(result, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleDelete' })
				toast.error(result.message)
			}
			await wait(500)
			router.push('/forms')
		} catch (e) {
			await deleteFormResult(parseInt(formId))
				.then(async () => {
					toast.success('Form Deleted Successfully!')
					await wait(500)
					router.push('/forms')
				})
				.catch((err) => {
					logError(err, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleDelete' })
					toast.error('Error deleting Form Result:', error)
				})
		} finally {
			setLoading(false)
		}
	}, [formId])


	const handleImages = useCallback(async (files) => {
		const images = await uploadImagesGetStringified(files)
		return images
	}, [])
	const handleSubmit = useCallback(
		async (data, dirtyFieldsMask, status, callback) => {
			setLoading(true)
			const imageId = import.meta.env.VITE_PROFILE_PICTURE_ID
			try {
				let imageBase64 = null
				const mappedFields = []
				const entries = Object.entries(data)
				//in the case of images, value is an array of files
				//so if value[0] exists => this is a file not a string
				const fileEntries = entries.filter(entry => entry[1]?.[0]?.name)
				const nonFileEntries = entries.filter(entry => !entry[1]?.[0]?.name)

				nonFileEntries.forEach(([key, value]) => {
					// Only include fields that are dirty
					if (dirtyFieldsMask.hasOwnProperty(key)) {
						const result = {
							fieldId: parseInt(key),
							value: Array.isArray(value) ? JSON.stringify(value) : value || ''
						}
						mappedFields.push(result)
					}
				})

				const batchSize = 2
				for (let i = 0; i < fileEntries.length; i += batchSize) {
					const batch = fileEntries.slice(i, i + batchSize)
					const batchPromises = batch.map(async ([key, value]) => {
						// Only include fields that are dirty
						if (dirtyFieldsMask.hasOwnProperty(key)) {
							const result = {
								fieldId: parseInt(key),
								value: Array.isArray(value) ? JSON.stringify(value) : value || '',
							}
							//if this field is the profile image
							if (imageId == key) {
								imageBase64 = await fileToBase64(value[0]).catch(logError)
							}
							const url = await handleImages(value)
							//console.log(url)
							// this is returned as an stringified array, so checking the length of the array in the string using length > 2
							result.value = url?.length > 2 ? url : ''
							return result
						}
						return null
					})
					mappedFields.push(...(await Promise.all(batchPromises)))
				}
				// Filter out null values to only keep dirty fields
				const filteredMappedFields = mappedFields.filter((field) => field !== null)
				const response = await updateForm(formId, {
					imageBase64,
					status,
					fields: filteredMappedFields,
				})
				if (response.status === 203) {
					toast.success('Form Updated Successfully!')
					callback()
					await wait(500)
					if (status === 'draft' || user?.role === 'SuperAdmin') {
						router.refresh()
					} else {
						router.push('/forms')
					}
				} else {
					logError(response, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleSubmit' })
					toast.error(response?.message)
				}
			} catch (e) {
				//if offline => write the data to the indexedDB
				if (e.response === undefined) {
					let imageBase64
					const profileImageValue = data[imageId]?.[0]?.name ? data[imageId][0] : null
					if (profileImageValue)
						imageBase64 = await fileToBase64(profileImageValue).catch(logError)

					const mappedFields = await Promise.all(
						Object.entries(data).map(async ([key, value]) => {
							// Only include fields that are dirty
							if (dirtyFieldsMask.hasOwnProperty(key)) {
								const result = {
									fieldId: parseInt(key),
									value: Array.isArray(value)
										? value[0] instanceof File || value[0] instanceof Blob
											? await Promise.all(value.map((val) => convertToBinary(val)))
											: JSON.stringify(value)
										: value,
									fileName:
										value?.[0] instanceof File || value?.[0] instanceof Blob
											? value.map((x) => x?.name)
											: undefined
								}
								return result
							}
							return null
						}),
					)
					// Filter out null values to only keep dirty fields
					const filteredMappedFields = mappedFields.filter((field) => field !== null)
					updateFormResult(parseInt(formId), {
						// for checking duplicates when syncing
						formUniqueId: new Date().getTime() + Math.floor(Math.random() * 10000000) + '',
						imageBase64,
						year: moment().year(),
						fields: filteredMappedFields,
						status,
						token: user?.token,
					})
						.then(async () => {
							toast.success('Form Updated Successfully!')
							callback()
							await wait(500)
							router.push('/forms')
						})
						.catch((e) => {
							logError(e, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleSubmit' })
							toast.error('Error Occurred while saving the form offline', e.message)
						})
				} else { //if error is from the server response or a generic error from the try block
					//if axios returned an error from the backend
					if (e.response?.data) {
						logError(e, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleSubmit' })
						toast.error(e.response?.data?.message)
					}
					else {
						logError(e, { src: 'src/pages/form/detail.jsx/FormDetailsPage/handleSubmit' })
						toast.error(`There was an error creating the form: ${e.message}`)
					}
				}
			} finally {
				setLoading(false)
			}
		},
		[formId, user, fields],
	)
	useEffect(() => {
		getFormDetailsCallback()
		return () => {
			emptyForm()
		}
	}, [])
	const [isOnline, setIsOnline] = useState(navigator.onLine)

	useEffect(() => {
		const handleOnlineEvent = async () => {
			setIsOnline(true)
			toast.success('Back Online!')
		}

		const handleOfflineEvent = () => {
			setIsOnline(false)
		}

		window.addEventListener('online', handleOnlineEvent)
		window.addEventListener('offline', handleOfflineEvent)

		return () => {
			window.removeEventListener('online', handleOnlineEvent)
			window.removeEventListener('offline', handleOfflineEvent)
		}
	}, [])
	return (
		<Box
			sx={{
				flexGrow: 1,
				py: 8,
			}}
		>
			<Container maxWidth="xl">
				<Stack spacing={4}>
					<Stack spacing={4}>
						<div>
							<Link
								color="text.primary"
								component={RouterLink}
								href={paths.forms}
								sx={{
									alignItems: 'center',
									display: 'inline-flex',
								}}
								underline="hover"
							>
								<SvgIcon sx={{ mr: 1 }}>
									<ArrowLeftIcon />
								</SvgIcon>
								<Typography variant="subtitle2">Forms</Typography>
							</Link>
						</div>
						<Stack
							alignItems="flex-start"
							direction={{
								xs: 'column',
								md: 'row',
							}}
							justifyContent="space-between"
							spacing={4}
						>
							<Stack alignItems="center" direction="row" spacing={2}>
								<Stack spacing={1}>
									<Typography variant="h4">Form Details</Typography>
									<Stack alignItems="center" direction="row" spacing={1}>
										<Typography variant="subtitle2">Form id:</Typography>
										<Chip label={formInfo?.id} size="small" />
									</Stack>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					{!isOnline && user?.role === 'Gatherer' && <OfflineCard />}
					{loading || formLoading ? (
						<Card elevation={16} sx={{ p: 4, mb: 2 }}>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Typography variant="h4">Loading Form...</Typography>
								<LinearProgress color="success" sx={{ width: '100%', my: 2 }} />
							</Box>
						</Card>
					) : (
						<>
							<Grid item xs={12}>
								<PropertyList>
									<PropertyListItem
										align={align}
										divider
										label="Status"
										value={formInfo?.status?.toUpperCase()}
										disableGutters
									/>
									<PropertyListItem
										align={align}
										divider
										label="Orphan Id"
										value={formInfo?.orphanId}
										disableGutters
									/>
									<PropertyListItem
										align={align}
										divider
										label="Year"
										value={formInfo?.year}
										disableGutters
									/>

									<PropertyListItem
										align={align}
										divider
										label="Created By"
										value={formInfo?.createdByUsername}
										disableGutters
									/>
									<PropertyListItem
										align={align}
										divider
										label="Created At"
										value={moment(formInfo?.createdAt).format('DD/MM/YYYY')}
										disableGutters
									/>
									{formInfo?.updatedByUsername && (
										<>
											<PropertyListItem
												align={align}
												divider
												label="Updated By"
												value={formInfo?.updatedByUsername}
												disableGutters
											/>
											<PropertyListItem
												align={align}
												label="Updated At"
												value={moment(formInfo?.updatedAt).format('DD/MM/YYYY')}
												disableGutters
											/>
										</>
									)}
								</PropertyList>
							</Grid>
							<DynamicForm
								fields={fields}
								isOnline={isOnline}
								userRole={user?.role}
								handleDelete={handleDelete}
								handleSubmit={handleSubmit}
								formStatus={formStatus}
								isFormCreatedOffline={isFormCreatedOffline}
							/>
						</>
					)}
				</Stack>
			</Container>
		</Box>
	)
}

export default FormDetailsPage
