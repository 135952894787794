import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export const useUserStore = create(
	persist(
		(set, get) => ({
			user: {},
			login: (user) => set({ user }),
			logout: () => set({ user: {} }),
		}),
		{
			name: 'user', // unique name
			storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
		},
	),
)
