import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import {
	Box,
	Button,
	Card,
	Container,
	Grid,
	InputAdornment,
	IconButton,
	OutlinedInput,
	SvgIcon,
	Tabs,
	Typography,
	Stack,
	Tab,
	Divider,
} from '@mui/material'
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd'
import { useUserStore } from 'src/store/users'
import CloseIcon from '@mui/icons-material/Close'
import FormsTable from 'src/sections/dashboard/forms/forms-table'
import { getForms } from 'src/api/forms'
import { getFormResults } from 'src/utils/indexedDb'
import OfflineCard from 'src/sections/dashboard/forms/offline-card'
import { toast } from 'react-hot-toast'
import { logError } from 'src/utils/logger'

const FormsList = () => {
	const user = useUserStore(({ user }) => user)
	const [paginationDetails, setPaginationDetails] = useState()
	const [forms, setForms] = useState([])
	const [isOnline, setIsOnline] = useState(navigator.onLine)
	const [offlineForms, setOfflineForms] = useState([])
	const tabs = useMemo(
		() => [
			{
				label: 'Drafts',
				value: 'draft',
			},
			{
				label: 'Published',
				value: 'submitted',
			},
		],
		[],
	)
	const [page, setPage] = useState(0)
	const queryRef = useRef(null)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [loading, setLoading] = useState(true)
	const [search, setSearch] = useState('')
	const [filters, setFilters] = useState({
		query: '',
		status: 'draft',
	})
	const handleTabsChange = useCallback(
		(event, value) => {
			setFilters((prev) => ({ ...prev, status: value }))
		},
		[setFilters],
	)
	const getFormsCallback = useCallback(async () => {
		setLoading(true)
		try {
			const res = await getForms(page + 1, rowsPerPage, filters.query, filters.status)
			setForms(res?.data?.docs)
			setPaginationDetails(res?.data)
		} catch (err) {
			try {
				// get the offline forms
				const results = await getFormResults()
				const firstNameFieldId = parseInt(import.meta.env.VITE_FIRST_NAME_FIELD_ID)
				const lastNameFieldId = parseInt(import.meta.env.VITE_LAST_NAME_FIELD_ID)

				setForms(
					results?.map((x, index) => ({
						...x,
						createdBy: user,
						createdAt: x.year,
						orphanId: {
							firstName: x.fields.find(f => f.fieldId === firstNameFieldId)?.value || 'Orphan',
							lastName: x.fields.find(f => f.fieldId === lastNameFieldId)?.value || index+1,
						},
					})),
				)
			} catch (err) {
				logError(err)
			}
		} finally {
			setLoading(false)
		}
	}, [user, filters, rowsPerPage, page])

	useEffect(() => {
		if (user?.token) {
			getFormsCallback()
		}
	}, [filters, rowsPerPage, page, user])

	const handlePageChange = (event, newPage) => {
		setPage(newPage)
	}

	const handleRowsPerPageChange = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		//current page may be larger than new total page number
		setPage(0)
	}
	const handleQueryChange = (event) => {
		event.preventDefault()
		setSearch(queryRef.current?.value)
	}
	const handleRemoveQuery = useCallback(() => {
		setSearch('')
		setFilters((prev) => ({ ...prev, query: '' }))
		setPage(0)
	}, [])

	useEffect(() => {
		//get the offline forms
		;(async () => {
			const results = await getFormResults()
			setOfflineForms(results)
		})()

		const handleOnlineEvent = async () => {
			setIsOnline(true)
			toast.success('Back Online!')
			getFormsCallback()
		}

		const handleOfflineEvent = () => {
			setIsOnline(false)
			getFormsCallback()
		}

		// Listen for messages from the service worker
		navigator.serviceWorker.onmessage = (event) => {
			if (event.data && event.data.type === 'syncNotification') {
				const notificationMessage = event.data.result
				// Handle the received message in your React component
				console.log('Received message from service worker:', notificationMessage)
				setOfflineForms([])
				getFormsCallback()
			}
		}

		window.addEventListener('online', handleOnlineEvent)
		window.addEventListener('offline', handleOfflineEvent)

		return () => {
			window.removeEventListener('online', handleOnlineEvent)
			window.removeEventListener('offline', handleOfflineEvent)
		}
	}, [])

	return (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				py: 8,
			}}
		>
			<Container maxWidth="xl">
				<Box sx={{ mb: 4 }}>
					<Grid container justifyContent="space-between" spacing={3} flexDirection="column">
						<Grid item>
							<Typography variant="h4">
								{user?.role === 'Gatherer' ? (isOnline ? 'Forms' : 'Saved Offline Forms') : 'Forms'}
							</Typography>
						</Grid>
						{user?.role === 'Gatherer' && isOnline && offlineForms?.length ? (
							<Grid item>
								<Typography variant="h6">Offline sync in progress...</Typography>
							</Grid>
						) : null}
					</Grid>
				</Box>
				<Card>
					{isOnline && (user?.role === 'SuperAdmin' || user?.role === 'Gatherer') && (
						<>
							<Tabs
								indicatorColor="primary"
								onChange={handleTabsChange}
								scrollButtons="auto"
								sx={{ px: 3 }}
								textColor="primary"
								value={filters.status}
								variant="scrollable"
							>
								{tabs.map((tab) => (
									<Tab key={tab.value} label={tab.label} value={tab.value} />
								))}
							</Tabs>
							<Divider />
						</>
					)}

					{isOnline ? (
						<Stack alignItems="center" direction="row" flexWrap="wrap" spacing={3} sx={{ p: 3 }}>
							<Box component="form" onSubmit={(e) =>{e.preventDefault(); setFilters((prev) => ({ ...prev, query: search }))}} sx={{ flexGrow: 1 }}>
								<OutlinedInput
									fullWidth
									onChange={handleQueryChange}
									inputProps={{
										ref: queryRef,
									}}
									value={search}
									endAdornment={
										!!filters.query ? (
											<IconButton aria-label="delete" onClick={handleRemoveQuery}>
												<CloseIcon />
											</IconButton>
										) : null
									}
									placeholder="Search Forms by Orphan, or Year, or Created By"
									startAdornment={
										<InputAdornment position="start">
											<SvgIcon>
												<SearchMdIcon />
											</SvgIcon>
										</InputAdornment>
									}
								/>
							</Box>
							<Button
								disabled={!search || search === filters.query}
								onClick={() => setFilters((prev) => ({ ...prev, query: search }))}
								variant="contained"
							>
								Search
							</Button>
						</Stack>
					) : user?.role === 'Gatherer' ? (
						<OfflineCard />
					) : null}
					<FormsTable
						userRole={user?.role}
						loading={loading}
						forms={forms}
						formsCount={paginationDetails?.totalDocs || 0}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						rowsPerPage={rowsPerPage}
						page={page}
					/>
				</Card>
			</Container>
		</Box>
	)
}

export default FormsList
