import { lazy } from 'react'

import { authRoutes } from './auth'
import { dashboardRoutes } from './dashboard'
import { paths } from 'src/paths'

const Error403Page = lazy(() => import('src/pages/403'))
const Error404Page = lazy(() => import('src/pages/404'))
const Error500Page = lazy(() => import('src/pages/500'))

export const routes = [
	...authRoutes,
	...dashboardRoutes,
	{
		path: paths.notAuthorized,
		element: <Error403Page />,
	},
	{
		path: paths.notFound,
		element: <Error404Page />,
	},
	{
		path: paths.serverError,
		element: <Error500Page />,
	},
	{
		path: '*',
		element: <Error404Page />,
	},
]
