import React, { useState, useCallback, useMemo } from 'react'
import {
  Box,
  Container,
  Autocomplete,
  CircularProgress,
  TextField,
  Grid,
  Typography,
  TableHead,
  Chip,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TablePagination,
  IconButton,
  SvgIcon
} from '@mui/material'
import { Scrollbar } from 'src/components/scrollbar'
import { Seo } from 'src/components/seo'
import { useDebounced } from 'src/hooks/use-debounced'
import { getOrphans } from 'src/api/orphans'
import { getOrphanFormsHistory } from 'src/api/forms'
import { toast } from 'react-hot-toast'
import { useIndexedDB } from 'src/hooks/useIDB'
import TrashIcon from '@untitled-ui/icons-react/build/esm/Trash03'
import useOnline from 'src/hooks/useOnline'
import { logError } from 'src/utils/logger'

const Detail = () => {
  const getOrphansCallback = useCallback(async (query) => {
    if (query?.length < 1) {
      setOrphanQuery(query)
      return
    }
    setOrphansLoading(true)
    try {
      const res = await getOrphans(1, 10, query)
      return res?.data?.docs
    } catch (err) {
      logError(err)
    } finally {
      setOrphansLoading(false)
      setOrphanQuery(query)
    }
  }, [])
  const [savedOrphanForms, setSavedOrphanForms] = useIndexedDB('orphans', 'forms', {})
  const [savedOrphans, setSavedOrphans] = useIndexedDB('orphans', 'orphans', [])
  const [orphansLoading, setOrphansLoading] = useState(false)
  const [orphans, fetchSetOrphans] = useDebounced(getOrphansCallback)
  const [orphanQuery, setOrphanQuery] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const isOnline = useOnline()

  const paginatedOrphans = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    const slicedOrphans = savedOrphans?.slice(start, end)
    return slicedOrphans
  }, [savedOrphans, page, rowsPerPage])

  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    //current page may be larger than new total page number
    setPage(0)
  }

  const selectOrphan = async (orphan) => {
    const savedForm = await saveForms(orphan.id)
    if (savedForm) {
      setSavedOrphans([...savedOrphans, orphan])
    }
  }

  const deleteOrphan = async (orphanId) => {
    const filteredOrphans = savedOrphans.filter(orphan => orphan.id != orphanId)
    const filteredOrphanForms = { ...savedOrphanForms }
    delete filteredOrphanForms[orphanId]
    setSavedOrphans(filteredOrphans)
    setSavedOrphanForms(filteredOrphanForms)
  }

  const saveForms = async (orphanId) => {
    if (savedOrphans?.find(orphan => orphan.id == orphanId)) {
      return false
    }
    if (orphanId) {
      try {
        const res = await getOrphanFormsHistory(orphanId, 1, 5)
        if (res?.forms?.docs?.length) {
          const { status, ...resToSave } = res
          setSavedOrphanForms({ ...savedOrphanForms, [orphanId]: resToSave })
          return true
        } else toast.error('No published forms found from previous years for selected orphan')
      } catch (e) {
        logError(e, {src:'src/pages/orphans/saved.jsx/Detail/saveForms'})
      }
    }
  }

  return (
    <>
      <Seo title="Saved Orphans" />
      <Box
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" spacing={3} flexDirection="column">
            <Grid item mb={3}>
              <Typography variant="h4">
                Saved Orphans <Chip
                  label={isOnline ? 'online' : 'offline'}
                  sx={{ backgroundColor: navigator.onLine ? '#03c04a' : '#E34234', color: 'white', fontWeight: '400', letterSpacing: '.1rem' }}
                />
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Autocomplete
              fullWidth
              id="options-filled"
              loading={orphansLoading}
              options={orphans || []}
              noOptionsText={orphanQuery ? 'No orphans found' : 'Search by Name/ID'}
              value={null}
              onInputChange={(e, value) => {
                fetchSetOrphans(value)
              }}
              onChange={(e, newValue) => {
                selectOrphan(newValue)
              }}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              getOptionLabel={(option) =>
                `${option?.firstName} ${option?.lastName} - id: ${option?.id}` || ''
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  label="Select orphan to save..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {orphansLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box sx={{ position: 'relative' }}>
            <Scrollbar>
              <Table sx={{ minWidth: 700 }}>
                <TableHead sx={{ visibility: 'visible' }}>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedOrphans?.map((orphan) => (
                    <TableRow
                      key={orphan?.id}
                    >
                      <TableCell>
                        <Chip label={orphan?.id} />
                      </TableCell>
                      <TableCell>{orphan?.firstName}</TableCell>
                      <TableCell>{orphan?.lastName}</TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => deleteOrphan(orphan?.id)}>
                          <SvgIcon>
                            <TrashIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
            <TablePagination
              width={'inherit'}
              component="div"
              count={savedOrphans?.length || 0}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Detail
