import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Box, Stack, Container, Typography, LinearProgress, Card } from '@mui/material'
import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-hot-toast'

import { wait } from 'src/utils/wait'
import { useFieldsStore } from 'src/store/fields'
import { useUserStore } from 'src/store/users'
import { useRouter } from 'src/hooks/use-router'
import DynamicForm from 'src/sections/dashboard/forms/dynamic-form'
import { getFields } from 'src/api/fields'
import { createForm, getOrphanFormsHistory } from 'src/api/forms'
import { addFormResult, convertToBinary } from '../../utils/indexedDb'
import { useFormStateStore } from 'src/store/formState'
import OfflineCard from 'src/sections/dashboard/forms/offline-card'
import { fileToBase64 } from 'src/utils/file-to-base64'
import { useIndexedDB } from 'src/hooks/useIDB'
import { logError } from 'src/utils/logger'
import { uploadImagesGetStringified } from 'src/utils/upload-images'

const NewFormPage = () => {
	const [loading, setLoading] = useState(false)
	const [selectedOrphan, setSelectedOrphan] = useState(null)
	const [showNoOrphanHistoryMsg, setShowNoOrphanHistoryMsg] = useState(false)
	const [savedOrphanForms] = useIndexedDB('orphans', 'forms', {})
	const fields = useFieldsStore(({ fields }) => fields)
	const setFields = useFieldsStore(({ setFields }) => setFields)
	const fillForm = useFormStateStore(({ fillForm }) => fillForm)
	const fillForms = useFormStateStore(({ setOrphanForms }) => setOrphanForms)

	const getFieldsCallback = useCallback(async () => {
		setLoading(true)
		try {
			const res = await getFields()
			if (res?.status === 200) {
				setFields(res?.data?.docs)
			}
		} catch (err) {
			logError(err)
		} finally {
			setLoading(false)
		}
	}, [setFields])
	const getForms = useCallback(async (orphanId) => {
		if (!!orphanId) {
			try {
				let res
				res = await getOrphanFormsHistory(orphanId, 1, 5)
				if (!res) {
					res = savedOrphanForms[orphanId]
				}
				if (res?.forms?.docs?.length) {
					setShowNoOrphanHistoryMsg(false)

					fillForm(res?.forms?.docs?.[0]?.fields?.map((x) => x?.formField))
					fillForms(
						res?.forms?.docs?.map((x) => ({
							year: x?.year,
							fields: x?.fields?.map((field) => field?.formField),
						})),
					)
				} else setShowNoOrphanHistoryMsg(true)
			} catch (e) {
				logError(e)
			}
		} else {
			setShowNoOrphanHistoryMsg(false)
			fillForm([])
			fillForms([])
		}
	}, [savedOrphanForms])
	useEffect(() => {
		getForms(selectedOrphan?.id)
	}, [selectedOrphan])
	const locationId = import.meta.env.VITE_LOCATION_FIELD_ID
	const [loc, setLoc] = useState({ lat: '', lng: '' })
	const user = useUserStore(({ user }) => user)
	const router = useRouter()
	const getCurrentPosition = useCallback(() => {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(pos) => {
					const crd = pos.coords
					resolve({ lat: crd.latitude, lng: crd.longitude })
				},
				(err) => {
					reject(err)
				},
				{
					enableHighAccuracy: true,
					timeout: 30000,
					maximumAge: 0,
				},
			)
		})
	}, [])

	const handleImages = useCallback(async (files) => {
		const images = await uploadImagesGetStringified(files)
		return images
	}, [])
	useEffect(() => {
		getCurrentPosition()
			.then((position) => {
				setLoc(position)
			})
			.catch((err) => {
				logError(err)
			})
	}, [])
	const handleSubmit = useCallback(
		async (data, dirtyFieldsMask, status, callback) => {
			setLoading(true)
			const imageId = import.meta.env.VITE_PROFILE_PICTURE_ID
			try {
				const orphanObj = selectedOrphan?.id ? { orphanId: parseInt(selectedOrphan?.id) } : {}
				let imageBase64
				const mappedFields = []
				// If selectedOrphan is not null, include only entries with non-null values
				const entries = Object.entries(data).filter(([key, value]) => selectedOrphan?.id ? value !== null
					// else if selectedOrphan is null, include only dirty fields
					: dirtyFieldsMask.hasOwnProperty(key)
				)
				//in the case of images, value is an array of files
				//so if value[0] exists => this is a file not a string
				const fileEntries = entries.filter(entry => entry[1]?.[0]?.name)
				const nonFileEntries = entries.filter(entry => !entry[1]?.[0]?.name)

				// ------------------------------- START OF NEW

				nonFileEntries.forEach(([key, value]) => {
					// Convert array values to JSON strings, otherwise keep the original value
					let resultValue = Array.isArray(value) ? JSON.stringify(value) : value || ''

					// Return the transformed field as an object with fieldId and value properties
					mappedFields.push({
						fieldId: parseInt(key), // Convert field ID from string to integer
						value: resultValue,
					})
				})

				const batchSize = 2
				for (let i = 0; i < fileEntries.length; i += batchSize) {
					const batch = fileEntries.slice(i, i + batchSize)
					const batchPromises = batch.map(async ([key, value]) => {
						const result = {
							fieldId: parseInt(key),
							value: Array.isArray(value) ? JSON.stringify(value) : value || '',
						}
						// If the current field is the profile image, convert the file to Base64
						if (imageId == key) {
							try {
								imageBase64 = await fileToBase64(value[0])
							}
							catch (err) {
								logError(err)
								toast.error(err.message)
							}
						}
						// Process and replace the image value with its URL
						const url = await handleImages(value)
						// this is returned as an stringified array, so checking the length of the array in the string using length > 2
						result.value = url?.length > 2 ? url : ''
						return result
					})
					mappedFields.push(...(await Promise.all(batchPromises)))
				}

				if (loc?.lat) {
					mappedFields.push({
						fieldId: parseInt(locationId),
						value: `https://www.google.com/maps?q=${loc.lat},${loc.lng}`
					})
				}
				const res = await createForm(
					{
						...orphanObj,
						imageBase64: imageBase64,
						year: moment().year(),
						fields: mappedFields,
						status,
					},
					user?.token,
				)
				if (res?.status === 200) {
					toast.success('Form Created Successfully')
					callback()
					await wait(700)
					router.push('/forms')
				} else {
					logError(res, { src: 'src/pages/form/new.jsx/NewFormPage/handleSubmit' })
					toast.error(res?.message)
				}
			} catch (e) {
				//if offline => write the data to the indexedDB
				if (e.response === undefined) {
					const imageId = import.meta.env.VITE_PROFILE_PICTURE_ID
					let imageBase64
					const profileImageValue = data[imageId]?.[0]?.name ? data[imageId][0] : null
					if (profileImageValue) {
						try {
							imageBase64 = await fileToBase64(profileImageValue)
						} catch (err) {
							logError(err)
							toast.error(err.message)
						}
					}

					const mappedFields = await Promise.all(
						Object.entries(data)
							// If selectedOrphan is not null, include only entries with non-null values
							.filter(([key, value]) => selectedOrphan?.id ? value !== null
								// else if selectedOrphan is null, include only dirty fields
								: dirtyFieldsMask.hasOwnProperty(key)
							)
							.map(async ([key, value]) => {
								try {
									// Determine if the value is an array of Files or Blobs
									const isFileOrBlobArray = Array.isArray(value) && (value[0] instanceof File || value[0] instanceof Blob)

									// Process the value based on its type
									const processedValue = isFileOrBlobArray
										? await Promise.all(value.map(convertToBinary)) // Convert Files/Blobs to binary
										: Array.isArray(value) ? JSON.stringify(value) : value // JSON stringify if array, else keep as is

									// Extract file names if value is an array of Files/Blobs, else undefined
									const fileName = isFileOrBlobArray ? value.map(x => x?.name) : undefined

									// Return the structured result object
									return {
										fieldId: parseInt(key),
										value: processedValue,
										fileName: fileName,
									}
								} catch (err) {
									logError(err)
									toast.error('Error Occurred while mapping fields: ', err.message)
								}
							})
					)

					try {
						await addFormResult({
							// for checking duplicates when syncing
							formUniqueId: new Date().getTime() + Math.floor(Math.random() * 10000000) + '',
							year: moment().year(),
							imageBase64: imageBase64,
							fields: mappedFields,
							status,
							token: user?.token,
						})
					} catch (err) {
						logError(err, { src: 'src/pages/form/new.jsx/NewFormPage/handleSubmit' })
						toast.error('Error Occurred while saving the form offline', err.message)
					}

					//setLoading(true)
					toast.success('Form will be synced when back online')
					callback()
					await wait(700)
					router.push('/forms')
				} else { //if error is from the server response or a generic error from the try block
					//if axios returned an error from the backend
					logError(e, { src: 'src/pages/form/new.jsx/NewFormPage/handleSubmit' })
					if (e.response?.data) {
						toast.error(e.response?.data?.message)
					}
					else {
						toast.error(`There was an error creating the form: ${e.message}`)
					}
				}
			} finally {
				setLoading(false)
			}
		},
		[selectedOrphan, user, loc, locationId, router, fields],
	)
	useEffect(() => {
		getFieldsCallback()
	}, [])
	const [isOnline, setIsOnline] = useState(navigator.onLine)

	useEffect(() => {
		const handleOnlineEvent = async () => {
			setIsOnline(true)
			toast.success('Back Online!')
		}

		const handleOfflineEvent = () => {
			setIsOnline(false)
		}

		window.addEventListener('online', handleOnlineEvent)
		window.addEventListener('offline', handleOfflineEvent)

		return () => {
			window.removeEventListener('online', handleOnlineEvent)
			window.removeEventListener('offline', handleOfflineEvent)
		}
	}, [])
	return (
		<Box
			sx={{
				flexGrow: 1,
				py: 8,
			}}
		>
			<Container maxWidth="xl">
				<Stack spacing={4}>
					<Stack spacing={4}>
						<Stack
							alignItems="flex-start"
							direction={{
								xs: 'column',
								md: 'row',
							}}
							justifyContent="space-between"
							spacing={4}
						>
							<Stack alignItems="center" direction="row" spacing={2}>
								<Stack spacing={1}>
									<Typography variant="h4">Create New Form</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					{!isOnline && user?.role === 'Gatherer' && <OfflineCard />}
					{loading ? (
						<Card elevation={16} sx={{ p: 4, mb: 2 }}>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
								}}
							>
								<Typography variant="h4">Loading Fields...</Typography>
								<LinearProgress color="success" sx={{ width: '100%', my: 2 }} />
							</Box>
						</Card>
					) : (
						<DynamicForm
							isOnline={isOnline}
							setSelectedOrphan={setSelectedOrphan}
							fields={fields}
							newForm
							orphan={selectedOrphan}
							selectedOrphan={selectedOrphan?.id}
							handleSubmit={handleSubmit}
							showNoOrphanHistoryMsg={showNoOrphanHistoryMsg}
						/>
					)}
				</Stack>
			</Container>
		</Box>
	)
}

export default NewFormPage
