import { createRoot } from 'react-dom/client'
import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import ErrorBoundary from 'src/components/error-boundary'
import { logError, logInfo } from 'src/utils/logger'
// import { registerServiceWorker } from './serviceWorkerSetup'

// registerServiceWorker()

import { App } from 'src/app'

// initially sync if there are any offline pending forms
navigator.serviceWorker?.ready.then((registration) => registration.sync.register('backgroundSync'))

// Request for notifications permissions
try{
	Notification.requestPermission()
}
catch(e)
{
	logInfo('Warning: Unable to enable notifications: ', e)
}

// Global error handler
window.onerror = function (message, source, lineno, colno, error) {
	logError(error || new Error(message), { source, lineno, colno })
}
  
// Global unhandled rejection handler
window.addEventListener('unhandledrejection', event => {
	logError(event.reason || event, { type: 'unhandledrejection' });
  });

const root = createRoot(document.getElementById('root'))

root.render(
	<HelmetProvider>
		<BrowserRouter>
			<ErrorBoundary>
				<Suspense>
					<App />
				</Suspense>
		</ErrorBoundary>
		</BrowserRouter>
	</HelmetProvider>,
)
