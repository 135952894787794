import { tokens } from '../tokens'

export const de = {
	[tokens.common.languageChanged]: 'Sprache geändert',
	[tokens.nav.academy]: 'Akademie',
	[tokens.nav.account]: 'Konto',
	[tokens.nav.analytics]: 'Analytik',
	[tokens.nav.auth]: 'Authentifizierung',
	[tokens.nav.blog]: 'Blog',
	[tokens.nav.browse]: 'Durchsuche',
	[tokens.nav.calendar]: 'Kalender',
	[tokens.nav.chat]: 'Plaudern',
	[tokens.nav.checkout]: 'Auschecken',
	[tokens.nav.concepts]: 'Konzepte',
	[tokens.nav.contact]: 'Kontakt',
	[tokens.nav.course]: 'Kurs',
	[tokens.nav.create]: 'Schaffen',
	[tokens.nav.crypto]: 'Crypto',
	[tokens.nav.customers]: 'Kunden',
	[tokens.nav.dashboard]: 'Dashboard',
	[tokens.nav.details]: 'Einzelheiten',
	[tokens.nav.ecommerce]: 'E-Commerce',
	[tokens.nav.edit]: 'Bearbeiten',
	[tokens.nav.error]: 'Fehler',
	[tokens.nav.feed]: 'Füttern',
	[tokens.nav.fileManager]: 'Dateimanager',
	[tokens.nav.finance]: 'Finanzen',
	[tokens.nav.fleet]: 'Flotte',
	[tokens.nav.forgotPassword]: 'Passwort Vergessen',
	[tokens.nav.invoiceList]: 'Rechnungen',
	[tokens.nav.jobList]: 'Stellenangebote',
	[tokens.nav.kanban]: 'Kanban',
	[tokens.nav.list]: 'Aufführen',
	[tokens.nav.login]: 'Anmeldung',
	[tokens.nav.logistics]: 'Logistik',
	[tokens.nav.mail]: 'E-Mail',
	[tokens.nav.management]: 'Verwaltung',
	[tokens.nav.orderList]: 'Aufträge',
	[tokens.nav.overview]: 'Überblick',
	[tokens.nav.pages]: 'Seiten',
	[tokens.nav.postCreate]: 'Beitrag erstellen',
	[tokens.nav.postDetails]: 'Beitragsdetails',
	[tokens.nav.postList]: 'Beitragsliste',
	[tokens.nav.pricing]: 'Preisgestaltung',
	[tokens.nav.productList]: 'Produkte',
	[tokens.nav.profile]: 'Profil',
	[tokens.nav.register]: 'Registrieren',
	[tokens.nav.resetPassword]: 'Passwort Zurücksetzen',
	[tokens.nav.socialMedia]: 'Sozialen Medien',
	[tokens.nav.verifyCode]: 'Code Überprüfen',
}
