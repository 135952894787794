import { useMemo } from 'react'

import { paths } from 'src/paths'
import { useUserStore } from 'src/store/users'
import Users03Icon from '@untitled-ui/icons-react/build/esm/Users03'
import SvgIcon from '@mui/material/SvgIcon'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import AlignLeft02Icon from 'src/icons/untitled-ui/duocolor/align-left-02'
import LayoutAlt02Icon from 'src/icons/untitled-ui/duocolor/layout-alt-02'
import AddIcon from '@mui/icons-material/Add'
import ListIcon from '@mui/icons-material/List'
import Bookmark from '@mui/icons-material/Bookmark'

export const useSections = () => {
	const userRole = useUserStore(({ user }) => user?.role)
	const sections = useMemo(
		() => ({
			SuperAdmin: [
				{
					subheader: 'Management',
					items: [
						{
							title: 'Users',
							icon: (
								<SvgIcon fontSize="small">
									<Users03Icon />
								</SvgIcon>
							),
							items: [
								{
									title: 'List',
									path: paths.users,
								},
								{
									title: 'Create',
									path: paths.newUser,
								},
							],
						},
						{
							title: 'Organisations',
							icon: <CorporateFareIcon fontSize="small" />,
							items: [
								{
									title: 'List',
									path: paths.organisations,
								},
								{
									title: 'Create',
									path: paths.newOrganisation,
								},
							],
						},
						{
							title: 'Orphans',
							icon: (
								<SvgIcon fontSize="small">
									<Users03Icon />
								</SvgIcon>
							),
							path: paths.orphans,
						},
						{
							title: 'Fields',
							icon: (
								<SvgIcon fontSize="small">
									<AlignLeft02Icon />
								</SvgIcon>
							),
							path: paths.fields,
							// items: [
							// 	{
							// 		title: 'List',
							// 		path: paths.fields,
							// 	},
							// 	{
							// 		title: 'Create',
							// 		path: paths.newField,
							// 	},
							// ],
						},
					],
				},
				{
					subheader: 'Insights',
					items: [
						{
							title: 'Reports',
							icon: (
								<SvgIcon fontSize="small">
									<LayoutAlt02Icon />
								</SvgIcon>
							),
							path: paths.reports,
						},
						{
							title: 'Forms',
							icon: <ListIcon fontSize="small" />,
							path: paths.forms,
						},
					],
				},
			],
			Admin: [
				{
					subheader: 'Management',
					items: [
						{
							title: 'Users',
							icon: (
								<SvgIcon fontSize="small">
									<Users03Icon />
								</SvgIcon>
							),
							items: [
								{
									title: 'List',
									path: paths.users,
								},
								{
									title: 'Create',
									path: paths.newUser,
								},
							],
						},
						{
							title: 'Organisations',
							icon: <CorporateFareIcon fontSize="small" />,
							items: [
								{
									title: 'List',
									path: paths.organisations,
								},
								{
									title: 'Create',
									path: paths.newOrganisation,
								},
							],
						},
						{
							title: 'Orphans',
							icon: (
								<SvgIcon fontSize="small">
									<Users03Icon />
								</SvgIcon>
							),
							path: paths.orphans,
						},
						{
							title: 'Fields',
							icon: (
								<SvgIcon fontSize="small">
									<AlignLeft02Icon />
								</SvgIcon>
							),
							path: paths.fields,
							// items: [
							// 	{
							// 		title: 'List',
							// 		path: paths.fields,
							// 	},
							// 	{
							// 		title: 'Create',
							// 		path: paths.newField,
							// 	},
							// 	{
							// 		title: 'Fields Preset',
							// 		path: paths.fieldPreset,
							// 	},
							// ],
						},
					],
				},
				{
					subheader: 'Insights',
					items: [
						{
							title: 'Reports',
							icon: (
								<SvgIcon fontSize="small">
									<LayoutAlt02Icon />
								</SvgIcon>
							),
							path: paths.reports,
						},
					],
				},
			],
			Editor: [
				{
					subheader: 'Management',
					items: [
						{
							title: 'Orphans',
							icon: (
								<SvgIcon fontSize="small">
									<Users03Icon />
								</SvgIcon>
							),
							path: paths.orphans,
						},
						/* {
							title: 'Organisations Presets',
							path: paths.organisations,
							icon: (
								<SvgIcon fontSize="small">
									<AlignLeft02Icon />
								</SvgIcon>
							),
						}, */
					],
				},
				{
					subheader: 'Insights',
					items: [
						{
							title: 'Reports',
							icon: (
								<SvgIcon fontSize="small">
									<LayoutAlt02Icon />
								</SvgIcon>
							),
							path: paths.reports,
						},
					],
				},
			],
			Gatherer: [
				{
					subheader: 'Forms',
					items: [
						{
							title: 'Create Form',
							icon: <AddIcon fontSize="small" />,
							path: paths.newForm,
						},
						{
							title: 'Forms List',
							icon: <ListIcon fontSize="small" />,
							path: paths.forms,
						},
					],
				},
				{
					subheader: 'Orphans',
					items: [
						{
							title: 'Saved Orphans',
							icon: <Bookmark fontSize="small" />,
							path: paths.savedOrphans,
						},
					],
				},
			],
		}),
		[],
	)

	return useMemo(() => {
		return sections[userRole]
	}, [userRole, sections])
}
