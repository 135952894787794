import { useMemo } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

export default function SiblingsForm({ title, value, onChange, columns, disabled }) {
  const rows = useMemo(() => {
    try {
      return JSON.parse(value || [])
    } catch (err) {
      return []
    }
  }, [value])
  const setRows = (updatedRows) => {
    onChange(JSON.stringify(updatedRows))
  }
  const onCellChange = (rowIndex, colIndex) => e => {
    const updatedRows = [...rows]
    updatedRows[rowIndex][columns[colIndex].label] = e.target?.value ?? e
    setRows(updatedRows)
  }

  const onAddRow = () => {
    setRows([...(rows || []), {}]);
  };

  const onRemoveRow = index => e => {
    e.stopPropagation()
    const newRows = [...rows]
    newRows.splice(index, 1);
    setRows(newRows);
  };

  return (
    <div style={{ border: '1px solid #eee', borderRadius: 10, padding: '0 1rem 1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p style={{ color: '#4b626e', fontSize: '0.9rem', fontWeight: '600' }}>{title}</p>
        <Button
          disabled={disabled}
          onClick={onAddRow}
          variant="contained"
          color="primary"
          style={{ margin: '0.5rem 0' }}
        >
          Add
        </Button>
      </div>

      {rows?.map((row, rowIndex) => (
        <Accordion key={rowIndex + ''}>
          <AccordionSummary
            sx={{
              '*': {
                transform: "none !important"
              }
            }}
            expandIcon={
              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <Button
                  disabled={disabled}
                  onClick={onRemoveRow(rowIndex)}
                  variant="contained"
                  color="primary"
                  style={{ margin: '0.5rem 0', backgroundColor: disabled ? '#ff000066' : '#D11A2A' }}
                >
                  Remove
                </Button>
                <ExpandMoreIcon />
              </div>
            }
          >
            <Typography>{row['First Name'] ? `${row['First Name']} ${row['Last Name'] || ''}` : `Member ${rowIndex + 1}`}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              gap: '0.5rem',
              flexWrap: 'wrap',
            }}
          >
            {columns?.map((col, colIndex) => {
              switch (col.type) {
                case 'datepicker':
                  return <DatePicker
                    slotProps={{
                      textField: {
                        InputLabelProps: {
                          sx: {
                            top: '-0.3rem',
                          }
                        },
                        sx: {
                          flex: 1,
                          minWidth: '20rem',
                          height: '2.45rem',
                          'div': {
                            height: '100% !important',
                          },
                          '.MuiInputLabel-root': {
                            marginTop: '-2px',
                            padding: 'none !important',
                          }
                        }
                      }
                    }}
                    sx={{
                      width: '100%',
                      flex: 1,
                      minWidth: '20rem',
                      height: '2.3rem',
                      marginTop: '-0.2rem',
                      input: {
                        padding: '1rem 0.5rem 0',
                      }
                    }}
                    maxDate={new Date()}
                    format="dd/MM/yyyy"
                    label={col.label}
                    disabled={disabled}
                    onChange={onCellChange(rowIndex, colIndex)}
                    value={
                      row[col.label] ? new Date(row[col.label]) || null : null
                    }
                  />
                case 'text':
                  return (
                    <TextField
                      key={col.label}
                      value={row[col.label]}
                      onChange={onCellChange(rowIndex, colIndex)}
                      placeholder={col.label}
                      disabled={disabled}
                      size='small'
                      sx={{
                        flex: 1,
                        minWidth: '20rem',
                        input: {
                          padding: '0.5rem',
                        }
                      }}
                    />
                  )
                case 'select':
                  return <TextField
                    disabled={disabled}
                    size='small'
                    select
                    InputLabelProps={{
                      shrink: false,
                      sx: {
                        position: 'absolute',
                        transform: 'none !important',
                        top: '0.7rem',
                        left: '0.5rem'
                        // height: '2.5rem !important',
                        // boxSizing: 'border-box',
                        // margin: '0 !important',
                      }
                    }}
                    SelectProps={{
                      sx: {
                        width: '100%',
                      },
                      inputProps: {
                        sx: {
                          width: '100%',
                          paddingTop: '1rem'
                        }
                      },
                    }}
                    fullWidth
                    value={row[col.label] || ''}
                    label={row[col.label] ? '' : col.label}
                    onChange={onCellChange(rowIndex, colIndex)}
                    sx={{
                      flex: 1,
                      minWidth: '20rem',
                      height: '2.45rem',
                      'div': {
                        height: '100% !important',
                      },
                      '.MuiInputLabel-root': {
                        marginTop: '-2px',
                        padding: 'none !important',
                      }
                    }}
                  >
                    {col.options?.sort().map(x => (
                      <MenuItem
                        value={x}
                        key={x}
                        sx={{
                          width: '100%',
                          input: {
                            padding: '0.2rem',
                          }
                        }}
                      >
                        {x}
                      </MenuItem>
                    ))}
                  </TextField>
                case 'paragraph':
                  return <TextField
                    disabled={disabled}
                    key={col.label}
                    size='small'
                    placeholder={col.label}
                    multiline
                    rows={4}
                    value={row[col.label] || ''}
                    onChange={onCellChange(rowIndex, colIndex)}
                    sx={{
                      width: '100%',
                      div: {
                        padding: '0rem !important',
                      },
                      textarea: {
                        padding: '0.5rem',
                      }
                    }}
                  />
              }
            })}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}