import React from 'react'
import packageJson from '../../../package.json'
import styles from './styles.module.css'

const appVersion = packageJson.version

function Footer() {
  return (
    <div className={styles.container}>
      <p>Developed by <a className={styles.itzCoding} href='https://itzcoding.com' target="_blank">itzCoding</a> for AlReaaya.</p>
      <p className={styles.version}>App version: <b>{appVersion}</b></p>
    </div>
  )
}

export default Footer