export const inhouseColumns = [
    {
        label: 'First Name',
        type: 'text',
    },
    {
        label: 'Last Name',
        type: 'text',
    },
    {
        label: 'D.O.B',
        type: 'datepicker',
    },
    {
        label: 'Gender',
        type: 'select',
        options: ['Male', 'Female'],
    },
    {
        label: 'Relationship',
        type: 'select',
        options: [
            'Mother',
            'Stepfather',
            'Sister',
            'Brother',
            'Stepsister',
            'Stepbrother',
            'Stepmother',
            'Half Sister',
            'Half Brother',
            'Grandfather',
            'Grandmother',
            'Uncle',
            'Aunt',
            "Uncle's Wife",
            "Aunt's Husband",
            'Cousin',
            'Nephew',
            'Niece'
        ],
    },
    {
        label: 'Status',
        type: 'select',
        options: [
            'Student',
            'Worker',
            'Daily Worker',
            'University Graduated and Looking for Work',
            'Divorced and Moved Back Home',
            'Imprisoned',
            'Jobless / Looking for a Job',
            'Too Young to Attend School',
            'Living at Home',
            'Married and Living at Home',
            'Has a Serious Illness and is Studying',
            'Has a Serious Illness and is Doing Nothing',
            'Has a Disability and is Studying',
            'Has a Disability and is Doing Nothing',
            'Dropped Out of School'
        ],
    },
    {
        label: 'Health',
        type: 'paragraph',
    },
]

export const outhouseColumns = [
    {
        label: 'First Name',
        type: 'text',
    },
    {
        label: 'Last Name',
        type: 'text',
    },
    {
        label: 'D.O.B',
        type: 'datepicker',
    },
    {
        label: 'Gender',
        type: 'select',
        options: ['Male', 'Female'],
    },
    {
        label: 'Relationship',
        type: 'select',
        options: ['Brother', 'Sister', 'Half Brother', 'Half Sister']
    },
    {
        label: 'Status',
        type: 'select',
        options: ['Married', 'Passed Away', 'Disappeared', 'Moved Away for Work', 'Moved Out', 'Living with Others', 'Ran Away from Home', 'Studying and living Abroad', 'Studying and Living Away from Home'],
    },
    {
        label: 'Health',
        type: 'paragraph',
    },
]
