import React from 'react'
import { logError } from 'src/utils/logger'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Do not update state to avoid showing fallback UI
    return { hasError: false }
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    logError(error, { errorInfo })
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary