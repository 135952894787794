import React from 'react'
import FieldCard from './field-card'
import { Table, TableBody, TableHead, TableRow, TableCell, TableContainer, Paper } from '@mui/material'
import update from 'immutability-helper'

const FieldsDnD = ({ section = '', fields, setFields, setOriginalFields, fieldCheck, onFieldDelete, handleSubmit }) => {
	const moveCard = (dragIndex, hoverIndex) => {
		const dragCard = fields.filter((x) => x?.section === section)?.[dragIndex]
		if (dragCard?.section !== section) {
			return
		}
		
		//we need to duplicate the fields because dragging can happen too quickly where react creates duplicate components while rendering
		setFields((prevFields) => {
			const clone = JSON.parse(JSON.stringify(prevFields)) // Deep clone
			const cardsInSection = clone.filter((x) => x?.section === section)
			
			// Perform the move operation
			const [removed] = cardsInSection.splice(dragIndex, 1)
			cardsInSection.splice(hoverIndex, 0, removed)
			
			// Replace the updated section back into the main array
			let j = 0
			for (let i = 0; i < clone.length; i++) {
				if (clone[i]?.section === section) {
					clone[i] = cardsInSection[j++]
				}
			}
	
			return clone
		})
	}

	return (
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead sx={{ visibility: 'visible' }}>
						<TableRow>
							<TableCell>Index</TableCell>
							<TableCell>Name</TableCell>
							<TableCell align="right">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{fields
							?.filter((x) => x?.section === section)
							?.map((field, i) => (
								<FieldCard
									saveFunc={handleSubmit}
									section={section}
									key={field.id}
									index={i}
									id={i}
									cardInfo={field}
									moveCard={moveCard}
									setFields={setFields}
									setOriginalFields={setOriginalFields}
									onFieldDelete={onFieldDelete}
									fieldCheck={fieldCheck}
								/>
							))}
					</TableBody>
				</Table>
			</TableContainer>
	)
}

export default FieldsDnD
