import PropTypes from 'prop-types'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'
import { alpha } from '@mui/system/colorManipulator'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState, useCallback, useContext } from 'react'
import { LightMode, DarkMode } from '@mui/icons-material'
import { AccountButton } from '../account-button'
import { SettingsContext } from 'src/contexts/settings' // Import the SettingsContext

const TOP_NAV_HEIGHT = 64
const SIDE_NAV_WIDTH = 280

export const TopNav = (props) => {
	const { onMobileNavOpen, ...other } = props
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'))
	const { handleUpdate, paletteMode } = useContext(SettingsContext)
	const [isDarkTheme, setIsDarkTheme] = useState(paletteMode === 'dark')

	const toggleTheme = useCallback(() => {
		setIsDarkTheme((prevTheme) => !prevTheme)
		// Call the theme switching function here
		if (isDarkTheme) {
			handleUpdate({ paletteMode: 'light' }) // Set the paletteMode to 'light'
		} else {
			handleUpdate({ paletteMode: 'dark' }) // Set the paletteMode to 'dark'
		}
	}, [isDarkTheme, handleUpdate])

	return (
		<Box
			component="header"
			sx={{
				backdropFilter: 'blur(6px)',
				backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
				position: 'sticky',
				left: {
					lg: `${SIDE_NAV_WIDTH}px`,
				},
				top: 0,
				width: {
					lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
				},
				zIndex: (theme) => theme.zIndex.appBar,
			}}
			{...other}
		>
			<Stack
				alignItems="center"
				direction="row"
				justifyContent="space-between"
				spacing={2}
				sx={{
					minHeight: TOP_NAV_HEIGHT,
					px: 2,
				}}
			>
				<Stack alignItems="center" direction="row" spacing={2}>
					{!lgUp && (
						<IconButton onClick={onMobileNavOpen}>
							<SvgIcon>
								<Menu01Icon />
							</SvgIcon>
						</IconButton>
					)}
				</Stack>
				<Stack alignItems="center" direction="row" spacing={2}>
					<IconButton onClick={toggleTheme}>
						{paletteMode === 'dark' ? <LightMode /> : <DarkMode />}
					</IconButton>
					<AccountButton />
				</Stack>
			</Stack>
		</Box>
	)
}

TopNav.propTypes = {
	onMobileNavOpen: PropTypes.func,
}
