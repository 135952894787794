import React, { memo, useCallback } from 'react'
import {
	Avatar,
	AvatarGroup,
	Divider,
	Card,
	Box,
	Button,
	Modal,
	Grid,
	TextField,
	Typography,
	FormControl,
	Stack,
	MenuItem,
	IconButton,
	SvgIcon,
	Tooltip,
} from '@mui/material'
import { Scrollbar } from 'src/components/scrollbar'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Eye } from '@untitled-ui/icons-react'
import { getImage } from 'src/api/forms'

const FilesModal = ({ files, open, setOpen }) => {
	const handlePreview = useCallback((item) => {
		getImage(item.original).then((r) => {
			const link = document.createElement('a')
			link.href = r.data
			link.download = r.config.params.key
			link.target = `_blank`
			link.click()
		})
	}, [])
	const FileCard = memo(({ item, key }) => {
		return (
			<Card
				key={key}
				sx={{
					backgroundColor: 'transparent',
					boxShadow: 0,
					transition: (theme) =>
						theme.transitions.create(['background-color, box-shadow'], {
							easing: theme.transitions.easing.easeInOut,
							duration: 200,
						}),
					'&:hover': {
						backgroundColor: 'background.paper',
						boxShadow: 16,
					},
				}}
				variant="outlined"
			>
				<Box sx={{ p: 2 }}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center', // Center vertically
							justifyContent: 'space-between', // Space between the two elements
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center', // Align items vertically within this box
							}}
						>
							<AttachFileIcon fontSize="small" />
							<Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}>
								<Typography noWrap variant="subtitle2">
									{item?.name}
								</Typography>
							</Box>
						</Box>
						<IconButton onClick={() => handlePreview(item)}>
							<Eye />
						</IconButton>
					</Box>
				</Box>
			</Card>
		)
	})
	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					width: '80%',
					textAlign: 'center',
					borderRadius: 1.5,
				}}
			>
				<Scrollbar style={{ maxHeight: 400 }}>
					<Stack alignItems="center" justifyContent="center" direction="row" spacing={2}>
						<Stack spacing={1}>
							<Typography variant="h4">
								{files?.length} {files?.length === 1 ? `File` : 'Files'}
							</Typography>
						</Stack>
					</Stack>
					<Grid container spacing={3} sx={{ mt: 2 }}>
						{files?.map((x, i) => (
							<Grid item sm={6} xs={12} key={i}>
								<FileCard item={x} key={i} />
							</Grid>
						))}
					</Grid>
				</Scrollbar>
			</Box>
		</Modal>
	)
}

export default FilesModal
