import { useState, useCallback } from 'react'
import toast from 'react-hot-toast'
import { logError } from 'src/utils/logger'

const usePopupModal = (deleteItem, successMessage, onItemDelete, isItemIdRequired = true) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const [item, setItem] = useState(null)

	const handleDeleteClick = useCallback((item) => {
		setIsModalOpen(true)
		setItem(item)
	}, [])

	const handleConfirmDelete = useCallback(async () => {
		if (!isItemIdRequired || item?.id) {
			setLoading(true)
			try {
				const response = await deleteItem(item?.id)

				if(response){
					if(response.status==200){
						toast.success(successMessage)
						onItemDelete()
					}else{
						logError(response, {src:"src/hooks/handleConfirmDelete"})
						toast.error(response.message? response.message : 'Unable to delete')
					}
				}
			} catch (e) {
				logError(e, {src:"src/hooks/handleConfirmDelete", stack: e.stack})
			} finally {
				setLoading(false)
				setIsModalOpen(false)
			}
		}
	}, [item])

	const handleCloseModal = useCallback(() => {
		setIsModalOpen(false)
	}, [])

	return {
		isModalOpen,
		loading,
		handleDeleteClick,
		handleConfirmDelete,
		handleCloseModal,
	}
}

export default usePopupModal
