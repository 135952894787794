import axios from 'axios'

const bearerToken = JSON.parse(localStorage.getItem('user') || '{}')?.state?.user?.token
const instance = axios.create({
	baseURL: import.meta.env.VITE_API_BASE_URL,
	headers: {
		Authorization: `Bearer ${bearerToken}`,
	},
})
let isRedirecting = false


instance.interceptors.response.use(
	(response) => response,
	(error) => {
			// Unauthorized
			if (!isRedirecting && !error.request?.responseURL?.includes('/auth/login') && error?.response?.status === 401) {
				isRedirecting = true
				// Clear the user session or token
				localStorage.removeItem('user')
				console.log('Unauthenticated: Redirecting to login')
				window.location.reload()
			}
		return Promise.reject(error)
	}
)

instance.interceptors.request.use(
	(config) => {
		// Modify config if needed before sending the request
		const accessToken = JSON.parse(localStorage.getItem('user') || '{}')?.state?.user?.token

		// Add the authorization header if an access token is available
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`
		}

		return config
	},
	(error) => {
		// Handle request errors
		return Promise.reject(error)
	},
)
export default instance
