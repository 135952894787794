import { useState, useCallback } from 'react'
import debounce from 'lodash.debounce'

export const useDebounced = (func, defaultValue = []) => {
	const [state, setState] = useState(defaultValue)
	const debouncedCall = useCallback(
		debounce(async (...params) => {
			const res = await func(...params)
			setState(res)
		}, 1000),
		[],
	)

	return [state, debouncedCall]
}
