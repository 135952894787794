import { Box, Button, Modal } from '@mui/material'
import React from 'react'

const PopupModal = ({ isModalOpen, handleConfirmDelete, handleCloseModal }) => {
	return (
		<>
			<Modal
				open={isModalOpen}
				onClose={handleCloseModal}
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
			>
				<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						width: 400,
						textAlign: 'center',
						borderRadius: 1.5,
					}}
				>
					<h2 id="modal-title">Confirmation</h2>
					<p id="modal-description">Are you sure you want to delete?</p>
					<Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
						<Button variant="contained" color="primary" onClick={handleConfirmDelete}>
							Confirm
						</Button>
						<Button variant="outlined" color="secondary" onClick={handleCloseModal}>
							Cancel
						</Button>
					</Box>
				</Box>
			</Modal>
		</>
	)
}

export default PopupModal
