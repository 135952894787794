import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash.isequal'
import { defaultSettings, initialState, SettingsContext } from './settings-context'
import { logError } from 'src/utils/logger'

const STORAGE_KEY = 'app.settings'

const restoreSettings = () => {
	let value = null

	try {
		const restored = window.localStorage.getItem(STORAGE_KEY)

		if (restored) {
			value = JSON.parse(restored)
		}
	} catch (err) {
		logError(err)
		// If stored data is not a strigified JSON this will fail,
		// that's why we catch the error
	}

	return value
}

const deleteSettings = () => {
	try {
		window.localStorage.removeItem(STORAGE_KEY)
	} catch (err) {
		logError(err)
	}
}

const storeSettings = (value) => {
	try {
		window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value))
	} catch (err) {
		logError(err)
	}
}

export const SettingsProvider = (props) => {
	const { children } = props
	const [state, setState] = useState(initialState)

	useEffect(() => {
		const restored = restoreSettings()

		if (restored) {
			setState((prevState) => ({
				...prevState,
				...restored,
				isInitialized: true,
			}))
		}
	}, [])

	const handleReset = useCallback(() => {
		deleteSettings()
		setState((prevState) => ({
			...prevState,
			...defaultSettings,
		}))
	}, [])

	const handleUpdate = useCallback((settings) => {
		setState((prevState) => {
			storeSettings({
				colorPreset: prevState.colorPreset,
				contrast: prevState.contrast,
				direction: prevState.direction,
				layout: prevState.layout,
				navColor: prevState.navColor,
				paletteMode: prevState.paletteMode,
				responsiveFontSizes: prevState.responsiveFontSizes,
				stretch: prevState.stretch,
				...settings,
			})

			return {
				...prevState,
				...settings,
			}
		})
	}, [])

	const handleDrawerOpen = useCallback(() => {
		setState((prevState) => ({
			...prevState,
			openDrawer: true,
		}))
	}, [])

	const handleDrawerClose = useCallback(() => {
		setState((prevState) => ({
			...prevState,
			openDrawer: false,
		}))
	}, [])

	const isCustom = useMemo(() => {
		return !isEqual(defaultSettings, {
			colorPreset: state.colorPreset,
			contrast: state.contrast,
			direction: state.direction,
			layout: state.layout,
			navColor: state.navColor,
			paletteMode: state.paletteMode,
			responsiveFontSizes: state.responsiveFontSizes,
			stretch: state.stretch,
		})
	}, [state])

	return (
		<SettingsContext.Provider
			value={{
				...state,
				handleDrawerClose,
				handleDrawerOpen,
				handleReset,
				handleUpdate,
				isCustom,
			}}
		>
			{children}
		</SettingsContext.Provider>
	)
}

SettingsProvider.propTypes = {
	children: PropTypes.node.isRequired,
}
