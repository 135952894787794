import { useState, useEffect, useCallback, useMemo } from 'react'
import ModalImage from 'react-modal-image'

import { getImage } from 'src/api/forms'
import styles from './styles.module.css'
import { Button } from '@mui/material'
import FilesModal from './modal'
import { logError } from 'src/utils/logger'

const Image = ({ imageKey, className, ...props }) => {
	const [files, setFiles] = useState([])
	const [state, setState] = useState('')
	const [showModal, setShowModal] = useState(false)
	const parser = useCallback((json) => {
		if (!json) {
			return ''
		}
		try {
			return JSON.parse(json)
		} catch (e) {
			return json
		}
	}, [])
	const isImage = useMemo(() => {
		const imageExtensions = ['.jpg', '.jpeg', '.jfif','.png', '.gif', '.bmp', '.svg', '.webp']
		return (
			imageExtensions.some((extension) => files?.[0]?.name?.toLowerCase?.()?.endsWith(extension)) &&
			parser(imageKey)?.length === 1
		)
	}, [files, imageKey])
	const getImages = useCallback(() => {
		const images = parser(imageKey)

		if (Array.isArray(images)) {
			images?.forEach((image) => {
				getImage(image)
					.then((r) => {
						setFiles((prev) => [...prev, { original: image, url: r?.data, name: r.config.params.key }])
					})
					.catch((e) => logError(e, {src:'src/sections/components/image/image.jsx/Image/getImages'}) || setState('No image preview available'))
			})
		}
	}, [imageKey])
	useEffect(() => {
		if (!imageKey) return setState('No image')
		if (files?.length) return
		setState('Loading..')
		getImages()
	}, [imageKey])
	return files ? (
		<>
			<FilesModal open={showModal} setOpen={setShowModal} files={files} />
			{isImage ? (
				<ModalImage
					hideDownload
					small={files[0]?.url}
					large={files[0]?.url}
					alt="preview"
					{...props}
					onError={() => setState('No image preview available')}
					className={className || styles.miniPreview}
				/>
			) : (
				<Button sx={{ ml: 0.5 }} onClick={() => setShowModal(true)} variant="contained">
					Preview
				</Button>
			)}
		</>
	) : (
		<p>{state}</p>
	)
}

export default Image
