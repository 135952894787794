import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import moment from 'moment'
export const useFieldsStore = create(
	persist(
		(set, get) => ({
			fields: [],
			date: moment().format('DD'),
			setFields: (fields) => {
				if (typeof fields === 'function') {
					return set(fields)
				} else {
					return set({ fields })
				}
			},
			setDate: (date) => set({ date }),
		}),
		{
			name: 'fields',
			storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
		},
	),
)
