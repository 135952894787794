import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { useUserStore } from 'src/store/users'
import packageJson from '../../package.json'

const appVersion = packageJson.version
const instrumentationKey = import.meta.env.VITE_AZURE_APP_INSIGHTS_INSTRUMENTATION_KEY

let appInsights = null

if (instrumentationKey) {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      enableAutoRouteTracking: true, // optional, enabled for automatic route tracking
    }
  })
  appInsights.loadAppInsights()
}

console.log(`App version: ${appVersion}`)

const getUserInfo = () => {
  const state = useUserStore.getState()
  const { username = '', id = '', fullName = '' } = state.user || {}
  return { username, id, fullName, appVersion }
}

const extractErrorDetails = (error) => {
  if(!error)
    return {}
  return {
    message: error.message || 'No error message available',
    name: error.name || 'No error name available',
    stack: error.stack || 'No stack trace available',
    response: error.response || 'No response available',
    ...error // Include other custom properties of the error
  }
}

const logError = (error, additionalInfo = {}) => {
  const user = getUserInfo()
  const info = { ...extractErrorDetails(error), ...additionalInfo, user }
  console.error(error, info)
  if (appInsights) {
    try {
      appInsights.trackException({ error, properties: info })
    } catch (e) {
      console.error('Failed to log error to Application Insights', e)
    }
  }
}

const logInfo = (message, additionalInfo = {}) => {
  const user = getUserInfo()
  const info = { ...additionalInfo, user }
  console.info(message, info)
  if (appInsights) {
    try {
      appInsights.trackTrace({ message, properties: info })
    } catch (e) {
      console.error('Failed to log info to Application Insights', e)
    }
  }
}

const logEvent = (name, properties = {}) => {
  const user = getUserInfo()
  const info = { ...properties, user }
  console.info(`Event: ${name}`, info)
  if (appInsights) {
    try {
      appInsights.trackEvent({ name, properties: info })
    } catch (e) {
      console.error('Failed to log event to Application Insights', e)
    }
  }
}

export { logError, logInfo, logEvent }