import { lazy } from 'react'

import { GuestGuard } from 'src/guards/guest-guard'
import { Layout as AuthLayout } from 'src/layouts/auth/classic-layout'
import { paths } from 'src/paths'

const LoginPage = lazy(() => import('src/pages/auth/login'))

export const authRoutes = [
	{
		path: paths.login,
		element: (
			<GuestGuard>
				<AuthLayout>
					<LoginPage />
				</AuthLayout>
			</GuestGuard>
		),
	},
]
