export const sections = [
	'BIODATA',
	'VISIT DETAILS',
	'ORPHAN’S PERSONALITY AND FAVORITES',
	'ORPHAN’S DETAILS',
	'HEALTH',
	'EDUCATION',
	'HOUSE DETAILS',
	'ATTACHMENTS',
	'GENERAL STATEMENT',
]
