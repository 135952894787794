import React, { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { wait } from 'src/utils/wait'
import { Box, Stack, Typography, Modal } from '@mui/material'
import FieldsForm from 'src/sections/dashboard/field/fields-form'
import { createField } from 'src/api/fields'
import { logError } from 'src/utils/logger'

const FieldModal = ({ open, onClose, updateCards }) => {
	const handleSubmit = useCallback(
		async (data, _, callback) => {
			setLoading(true)
			try {
				const response = await createField(data)
				if (response.status === 200) {
					toast.success('Field Created Successfully!')
					callback()
					updateCards((prev) => [...prev, response?.data])
					await wait(500)
					onClose()
				} else {
					logError(response, {src:'src/sections/dashboard/field/field-modal.jsx/FieldModal/handleSubmit'})
					toast.error(`Field Label already exists. Status: ${response.status}`)
				}
			} catch (e) {
				logError(e)
			} finally {
				setLoading(false)
			}
		},
		[updateCards],
	)
	const [loading, setLoading] = useState(false)

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: { xs: '90%', md: '70%' },
					bgcolor: 'background.paper',
					boxShadow: 24,
					p: 4,
					borderRadius: 1.5,
				}}
			>
				<Stack
					alignItems="flex-start"
					direction={{
						xs: 'column',
						md: 'row',
					}}
					justifyContent="space-between"
					spacing={4}
				>
					<Stack alignItems="center" direction="row" spacing={2}>
						<Stack spacing={1}>
							<Typography variant="h4">Create New Field</Typography>
						</Stack>
					</Stack>
				</Stack>
				<FieldsForm handleSubmit={handleSubmit} loading={loading} onClose={onClose} updateCards={updateCards} />
			</Box>
		</Modal>
	)
}

export default FieldModal
