import { useCallback, useEffect, useState } from 'react'
import { useUserStore } from 'src/store/users'
import { useRouter } from 'src/hooks/use-router'
import { paths } from 'src/paths'

export const GuestGuard = (props) => {
	const { children } = props
	const router = useRouter()
	const [checked, setChecked] = useState(false)
	const user = useUserStore(({ user }) => user)

	const check = useCallback(() => {
		if (user?.token) {
			router.replace(user.role === 'Gatherer' ? paths.newForm : paths.index)
		} else {
			setChecked(true)
		}
	}, [user, router])

	// Only check on mount, this allows us to redirect the user manually when auth state changes
	useEffect(
		() => {
			check()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	if (!checked) {
		return null
	}

	// If got here, it means that the redirect did not occur, and that tells us that the user is
	// not authenticated / authorized.

	return <>{children}</>
}
