import React, { useCallback, useEffect, useState, useMemo } from 'react'
import toast from 'react-hot-toast'
import { Box, Card, Container, Grid, Stack, Typography, Button } from '@mui/material'
import { sections } from 'src/constants/sections'
import { useMounted } from 'src/hooks/use-mounted'
import { useFieldsStore } from 'src/store/fields'
import FieldsDnD from 'src/sections/dashboard/field/fields-dnd'
import { getFields, updateMultipleFields } from 'src/api/fields'
import { useRouter } from 'src/hooks/use-router'
import AddIcon from '@mui/icons-material/Add'
import FieldModal from 'src/sections/dashboard/field/field-modal'
import { logError } from 'src/utils/logger'

const FieldPreset = () => {
	const setFields = useFieldsStore(({ setFields }) => setFields)
	const [presets, setPresets] = useState([])
	const isMounted = useMounted()
	const [loading, setLoading] = useState(true)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const router = useRouter()
	const handleSubmit = useCallback(async () => {
		setLoading(true)
		try {
			const response = await updateMultipleFields({
				orderedFields: presets?.map((x, index) => ({ id: x.id, section: x.section, indexNumber: index })),
			})
			if (response?.status === 203) {
				toast.success('Fields Updated Successfully!')
				// await wait(750)
				// router.refresh()
			} else {
				logError(response, {src:'src/pages/fields/field-preset.jsx/FieldPreset/handleSubmit'})
				toast.error(response?.message)
			}
		} catch (e) {
			logError(e)
		} finally {
			setLoading(false)
		}
	}, [presets])
	const [fieldsLoading, setFieldsLoading] = useState(false)
	const getFieldsCallback = useCallback(async () => {
		setFieldsLoading(true)
		try {
			const res = await getFields()
			if (isMounted() && res?.status === 200) {
					setFields(res?.data?.docs?.map((x) => ({ ...x, checked: false })))
					setPresets(res?.data?.docs)
				}
		} catch (err) {
			logError(err)
		} finally {
			setFieldsLoading(false)
		}
	}, [setFields])

	const handleOpenModal = () => {
		setIsModalOpen(true)
	}

	const handleCloseModal = () => {
		setIsModalOpen(false)
	}

	useEffect(() => {
		getFieldsCallback()

		return () => {
			setLoading(true)
		}
	}, [])

	return (
		<Box
			sx={{
				flexGrow: 1,
				py: 8,
			}}
		>
			<Container maxWidth="xl">
				<Stack spacing={4}>
					<Box>
						<Grid container justifyContent="space-between" spacing={3}>
							<Grid item>
								<Typography variant="h4">Fields</Typography>
							</Grid>
							<Grid item>
								<Button
									startIcon={<AddIcon fontSize="small" />}
									onClick={handleOpenModal}
									variant="contained"
								>
									Add
								</Button>
								<FieldModal open={isModalOpen} onClose={handleCloseModal} updateCards={setPresets} />
							</Grid>
						</Grid>
					</Box>
					<Card sx={{ width: '100%', pb: 2 }}>
						<Box
							sx={{
								width: '100%',
								alignItems: 'center',
								display: 'flex',
								flexWrap: 'wrap',
								m: -1.5,
								p: 3,
							}}
						>
							{sections.map((section, index) => (
								<React.Fragment key={index}>
									<Typography variant="h5" sx={{ my: 2 }}>
										{section}
									</Typography>
									<FieldsDnD
										section={section}
										fields={presets}
										setFields={setPresets}
										setOriginalFields={setFields}
										onFieldDelete={getFieldsCallback}
										fieldCheck={true}
										handleSubmit={handleSubmit}
									/>
								</React.Fragment>
							))}
						</Box>
					</Card>
				</Stack>
			</Container>
		</Box>
	)
}

export default FieldPreset
